<template>
    <div class="function-layout">
        <div class="function-title" v-html="tt('랩매니저 PRO에<br />어떤 기능이 있는지 살펴볼까요?')"></div>
        <div class="function-list">
            <div class="function-item" v-for="item in functions" :key="item.title">
                <div class="item-title">
                    {{ tt(item.title) }}
                </div>
                <div class="sub-item" v-for="subItem in item.list" :key="subItem.id">
                    <div class="sub-item-title">{{ tt(subItem.title) }}</div>
                    <div class="sub-item-content"><span>{{ tt(subItem.content) }}</span> <img :id="`tooltip-${subItem.id}`" @click="() => (showId = subItem.id)" src="@/assets/image/icon-question.svg" /></div>
                    <b-tooltip :show="showId == subItem.id" :target="`tooltip-${subItem.id}`" placement="topleft" triggers="click">
                        <div v-html="subItem.tooltip"> </div>
                    </b-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        functions() {

            return [
                {
                    title: '물품관리',
                    list: [
                        {
                            id:1,
                            title: 'AI 기반 시약 자동 등록 기능',
                            content: '라벨 촬영만 하면 끝!',
                            tooltip: '시약 라벨 촬영하여 등록 시<br />AI 기반으로 정보 자동 입력 가능',
                        },
                        {
                            id:2,
                            title: '등록 시약 위치 확인 기능',
                            content: '위치 및 보관함 정보 확인',
                            tooltip: '필요한 시약 및 연구물품이<br />어디에 있는지 손쉽게 확인 가능',
                        },
                        {
                            id:3,
                            title: '소분 및 GHS 라벨 생성 가능',
                            content: '소분 및 화합물 생성 시 필수',
                            tooltip: '소분 및 화합물 생성 시 새로운<br />물품 등록 및 GHS 라벨 생성하여<br />라벨 출력 후 물품 부착 가능',
                        }
                    ]
                },
                {
                    title: '안전관리',
                    list: [
                        {
                            id:4,
                            title: 'MSDS 조회 및 관리',
                            content: 'MSDS 70만종 제공',
                            tooltip: 'Sigma-Aldrich, Alfa-Aesar,<br />TCI 등 총 70만종 상품의 MSDS<br />제공 (지속적인 업데이트 중)',
                        },
                        {
                            id:5,
                            title: '위험물 지정수량 배수 관리',
                            content: '지정량 이상 보관되지 않도록',
                            tooltip: '1류뷰터 6류까지 저장소별<br />위험물 지정수량 및 배수 관리,<br />저정량 초과 시 알림',
                        },
                        {
                            id:6,
                            title: '법령별 관리대장 자동 입력',
                            content: '화학물질 필수 관리대장',
                            tooltip: '유해화학물질 및 특별관리물질<br />관리대장 자동 입력',
                        },
                        {
                            id:7,
                            title: '특수건강진단 대상자 알림',
                            content: '개개인별 필수 건강진단 알림',
                            tooltip: '산업안전보건법 제 130조에 따라<br />특수건강진단 대상 유해인자에<br />노출될 시 특수건강진단 대상자에<br />사용자 본인의 이름이 노출됨',
                        },
                        {
                            id:8,
                            title: '그룹별 법령물품 소지량 조회',
                            content: '화학물질 법령별 구분',
                            tooltip: '담당 그룹별로 보유한 화학물질을<br />법령별로 분류하여 확인 가능',
                        }
                    ]
                },
                {
                    title: '구매관리',
                    list: [
                        {
                            id:9,
                            title: '지출결의서 작성 및 관리',
                            content: '결재 및 구매 진행',
                            tooltip: '지출결의서 작성 및 결재라인<br />승인 후 구매 진행',
                        },
                        {
                            id:10,
                            title: '상품 검색 및 바로 구매 가능',
                            content: '필요물품 바로 구매',
                            tooltip: '지출결의서 승인 없이 바로 주문<br />가능한 구매 기능, 구매한 상품은<br />배송완료 시 입고관리 메뉴에서<br />확인 가능',
                        },
                    ]
                },
                {
                    title: '예산관리',
                    list: [
                        {
                            id:11,
                            title: '그룹별 예산 등록 및 관리',
                            content: '예산 내 구매활동 가능',
                            tooltip: '설정된 연도별, 월별 예산 내 물품<br />구매할 수 있도록 구매한도금액<br />설정 및 관리 가능',
                        },
                    ]
                }
            ]
        }
    }, 
    data() {
        return {
            showId: null
        }
    },
}
</script>
<style lang="scss" scoped>
.function-layout {   
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    padding: 90px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        width: 800px;
    }
    .function-title {
        width: 500px;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        color: #000;
    }
    .function-list {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        gap: 60px;
        .function-item {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .item-title {
                padding: 0px 20px;
                padding-bottom: 10px;
                font-size: 25px;
                font-weight: 800;
                font-size: 25px;
                width: 100%;
                border-bottom: 1px solid #000;
                margin-bottom: 10px;
            }
            .sub-item {
                padding: 0px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .sub-item-title {
                    font-size: 20px;
                    font-weight: 500;
                }
                .sub-item-content {
                    cursor: pointer;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 400;
                }
            }
        }
    }
}
@media (max-width: 767px) {
.function-layout {   
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        width: 100%;
    }
    .function-title {
        width: 220px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        color: #000;
    }
    .function-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        .function-item {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .item-title {
                padding: 0px 10px;
                padding-bottom: 10px;
                font-size: 20px;
                font-weight: 800;
                width: 100%;
                border-bottom: 1px solid #000;
                margin-bottom: 5px;
            }
            .sub-item {
                padding: 0px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .sub-item-title {
                    font-size: 15px;
                    font-weight: 500;
                }
                .sub-item-content {
                    cursor: pointer;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 400;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

}
</style>