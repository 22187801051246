<template>
	<div id="1027-event" class="event-page">
		<div class="intro">
			<img src="@/assets/image/event/discount/event-intro.png" />
			<button @click="this.$bvModal.show('inquiry-form')">
				<img src="@/assets/svg/event/discount/request-btn.svg" />
			</button>
		</div>
		<div class="content">
			<div class="content-img"><img src="@/assets/image/event/discount/event-content.png" /></div>
			<div class="content-btn-wrap">
				<a href="https://www.lab-manager.com/?utm_source=event&utm_medium=50mms&utm_campaign=more_event" target="_blank">
					<img src="@/assets/svg/event/discount/btn-more.svg" />
				</a>
				<p><img src="@/assets/svg/event/discount/request-txt.svg" /></p>
				<div>
					<a v-if="!$root.isPc" href="tel:070-4189-9493"><img src="@/assets/svg/event/discount/btn-call.svg"/></a>
					<router-link to="/Request"><img src="@/assets/svg/event/discount/btn-email.svg"/></router-link>
					<a href="http://pf.kakao.com/_aCNLb/chat"><img src="@/assets/svg/event/discount/btn-kakao.svg"/></a>
				</div>
			</div>
		</div>

		<modal-inquiry-usage />
	</div>
</template>

<script>
import ModalInquiryUsage from '@/components/modals/ModalInquiryUsage'

export default {
	components: {
		ModalInquiryUsage,
	},
	methods: {},
}
</script>

<style lang="scss">
.event-page {
	max-width: 640px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	.intro {
		position: relative;
		background-color: #fff3d6;
		> img {
			width: 85%;
		}
		button {
			margin-top: 40px;
			max-width: 90%;
			width: 300px;
			margin-bottom: 45px;
			img {
				width: 100%;
			}
		}
	}
	.content {
		width: 100%;
		background-color: white;
		padding-top: 40px;
		.content-img {
			img {
				width: 85%;
			}
		}
		.content-btn-wrap {
			margin-top: 40px;
			> a {
				width: 100%;
				display: block;
				margin: 0 auto 50px;
				img {
					width: 85%;
				}
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 20px 0 60px;
				a {
					display: inline-block;
					margin: 0 20px;
				}
			}
		}
	}
}
</style>
