<template>
	<div id="GatheringForm">
		<div class="user-info">
			<div class="content">
				<img src="@/assets/image/gathering/gathering-type1-intro.png" />

				<button v-b-modal.gathering-form @click="goRequest">
					<img src="@/assets/svg/gathering/gathering-type1-intro-btn.svg" />
				</button>
			</div>
		</div>
		<div class="partner content">
			<img src="@/assets/svg/gathering/partner-text.svg" />
			<img src="@/assets/image/gathering/partners.png" />
		</div>
		<div class="promotion content">
			<img src="@/assets/image/gathering/gathering-type1-image.png" />
			<img src="@/assets/image/gathering/gathering-type1-image2.png" />
		</div>
		<div class="marketing content">
			<img class="review" src="@/assets/svg/gathering/gathering-review1.svg" />
			<div class="opinions">
				<img src="@/assets/svg/gathering/gathering-opinion1.svg" />
				<mobile-carousel :autoplay="false" :list="optionList.list1" contentClass="opinion" imgType="svg" />
				<img src="@/assets/svg/gathering/gathering-opinion2.svg" />
				<mobile-carousel :autoplay="false" :list="optionList.list2" contentClass="opinion" imgType="svg" />
			</div>
		</div>
		<div class="footer">
			<div class="content">
				<img class="intro" src="@/assets/svg/gathering/gathering-intro3.svg" />
				<button v-b-modal.gathering-form>
					<img src="@/assets/svg/gathering/gathering-type1-intro-btn.svg" />
				</button>
			</div>
		</div>

		<modal-gathering-form />
	</div>
</template>

<script>
import ModalGatheringForm from '@/components/modals/ModalGatheringForm'
import MobileCarousel from '@/ui/page/MobileCarousel'

export default {
	name: 'GatheringForm',
	components: {
		ModalGatheringForm,
		MobileCarousel,
	},
	created() {
		this.setImage()
	},
	data() {
		return {
			optionList: {},
			isAgree: false,
		}
	},
	methods: {
		goRequest() {
			window.ga('send', 'event', 'now-request', 'now-request')
			window.marketingEvent('conversion')
		},
		setImage() {
			this.optionList = {
				list1: [],
				list2: [],
			}
			for (let i = 1; i <= 4; i++) {
				this.optionList.list1.push({ image: `gathering/gathering-opinion1-${i}.svg` })
				this.optionList.list2.push({ image: `gathering/gathering-opinion2-${i}.svg` })
			}
		},
	},
}
</script>

<style lang="scss">
#GatheringForm {
	.content {
		width: 640px;
		margin: 0 auto;
		text-align: center;
		img {
			width: 100%;
		}
	}
	.user-info,
	.footer {
		width: 100%;
		background: linear-gradient(315deg, #fec741 0%, #fed671 100%);
		padding: 100px 0 60px;
		button {
			margin-top: 30px;
		}
	}
	.partner {
		padding: 40px 0;
		img:last-child {
			margin-top: 40px;
		}
	}
	.marketing {
		padding-top: 120px;
		.review {
		}
		.opinions {
			> img {
				width: 388px;
				margin: 100px 0 40px;
			}
		}
		.VueCarousel-slide {
			img {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
	.footer {
		padding: 60px 0 40px;
		min-height: unset;
		margin-top: 80px;
	}
}

@media (max-width: 767px) {
	#GatheringForm {
		.content {
			width: 90%;
			img {
				width: 100%;
			}
		}
		.user-info {
			padding: 60px 0 60px;
		}
		.marketing {
			padding-top: 40px;
			.review {
				width: 90%;
			}
			.opinions {
				> img {
					width: 70%;
					margin: 60px 0 40px;
				}
			}
			.VueCarousel-slide {
				img {
					width: 90%;
					margin: 0 auto;
				}
			}
		}
	}
}
</style>
