<template>
	<div id="container">
		<img v-show="current === 1" class="image" src="@/assets/image/event/01/complete.png">
		<div class="virtual-btn" id="btn4" @click="moveToMain"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			current : 1
		}
	},
	methods : {
		moveToMain() {
			location.href = '/'
		}
	}
}
</script>

<style lang="scss">
#container {
	position: relative;
	max-width: 800px;
	margin : 0 auto;
	width : 100%;
	.image {
		width: 100%;
	}
	.virtual-btn {
		position: absolute;
		width : 30%;
		height: 4%;
		background: transparent;
		cursor: pointer;
		&#btn4 {
			top :79%;
			left : 9%;
			height: 7%;
			width: 82%
		}
	}
}
</style>