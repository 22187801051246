<template>
    <b-modal id="estimate-form" ref="modal" size="md" centered hide-footer @show="init" @hidden="init">
		<template #modal-title>
            <b v-text="`라이선스 견적서 발급`"></b>
			<p v-html="`항목을 입력하고 선택한 후 다운로드 버튼을 누르면 바로 발급됩니다.`"></p>
        </template>
		<div class="modal-body">
           <div class="input-wrapper" :class="{ error: !valid && !estimateInfo.instituteName}">
				<p>
					{{ tt('기관명') }}
					<span class="required">*</span>
				</p>
				<input type="text" :placeholder="tt('회사, 학교, 기관 등의 이름')" v-model="estimateInfo.instituteName" />
				<span>{{ tt('기관명을 입력해 주세요.') }}</span>
			</div>
            <div class="input-wrapper" :class="{ error: !valid && !estimateInfo.email}">
				<p>
					{{ tt('이메일 주소') }}
					<span class="required">*</span>
				</p>
				<input type="text" :placeholder="tt('수신 가능한 이메일 주소')" v-model="estimateInfo.email" />
				<span>{{ tt('이메일 주소를 입력해 주세요.') }}</span>
			</div>
            <div class="input-wrapper" :class="{ error: !valid && !estimateInfo.phone}">
				<p>
					{{ tt('연락처') }}
					<span class="required">*</span>
				</p>
				<input type="text" :placeholder="tt('연락 가능한 전화번호')" v-model="estimateInfo.phone" @input="setPhoneForm" />
				<span>{{ tt('연락처를 입력해 주세요.') }}</span>
			</div>
            <div class="input-wrapper" :class="{ error: !valid && !licenseType}">
				<p>
					{{ tt('라이선스 종류 선택') }}
					<span class="required">*</span>
				</p>
                    <b-dropdown button-class="select-wrap" ref="dropdown" no-caret :toggle-class="{'option-wrapper': true, error: !valid && !licenseType }" variant="link" right>
                        <template #button-content>
                            <p v-if="licenseType" v-html="`랩매니저 PRO 라이선스 <b>${type(licenseType)}</b>`"></p>
                            <p v-else style="color: #999">라이선스 종류 선택</p>
                        </template>
                        <b-dropdown-item @click.stop="changeOption(index)" :key="`item-${index}`" v-for="(item, index) in licenseList">
                            <p v-html="`랩매니저 PRO 라이선스 <b>${item.name}</b>`"></p>
                        </b-dropdown-item>
                    </b-dropdown>
				<span>{{ tt('라이선스 종류를 선택해 주세요.') }}</span>
			</div>
            <div class="input-wrapper">
                <p>계정 수</p>
                <div class="num-box">
					<button :class="{ cant: licenseType == 'EDUCATION' || accountCount <= 1 }" @click="minusAccount">
					    <img src="@/assets/svg/input-minus.svg" />
					</button>
                    <p class="license-count-unit" v-if="licenseType == 'EDUCATION'">무제한</p>
                    <p class="license-count-unit" v-else>{{ accountCount }}</p>
					<button :class="{ cant: licenseType == 'EDUCATION' }" @click="plusAccount">
						<img src="@/assets/svg/input-plus.svg" />
					</button>
				</div>
            </div>
            <div class="input-wrapper">
                <p>사용 기간</p>
                <div class="period-wrap">
                    <div class="input-div">
						<input id="year" type="radio" :value="'YEAR'" v-model="selectedPeriod" />
                        <label for="year">연간결제</label>
                        <img v-if="selectedPeriod == 'YEAR' && licenseType != 'EDUCATION'" class="tooltip" :src="require('@/assets/svg/estimate/year-tooltip.svg')">
					</div>
                    <div v-b-tooltip.hover.html.top="tooltipText" class="input-div" :class="{readonly: licenseType == 'EDUCATION'}">
						<input id="month" type="radio" :disabled="licenseType == 'EDUCATION'" :value="'MONTH'" v-model="selectedPeriod" />
						<label for="month">월간결제</label>
                        <img v-if="selectedPeriod == 'MONTH'" class="tooltip left" :src="require('@/assets/svg/estimate/month-tooltip.svg')">
                    </div>
                    <div class="num-box">
                        <button :class="{ cant: period <= count }" @click="minusPeriod">
                            <img src="@/assets/svg/input-minus.svg" />
                        </button>
                        <p class="license-count-unit">{{ tt('${1}개월', period) }}</p>
                        <button @click="plusPeriod">
                            <img src="@/assets/svg/input-plus.svg" />
                        </button>
                    </div>
                </div>
            </div>
			<div class="require-info">
				<p class="agree-box" rol="tab">
					<label for="agree" @click="isAgree = !isAgree">
						<img v-if="isAgree" src="@/assets/svg/ic-check-on.svg" />
						<img v-else src="@/assets/svg/ic-check-off.svg" /> 
						[필수] 개인정보 수집 및 이용 동의
					</label>
					<!-- <img class="arrow" @click="isAreeInfoFold = !isAreeInfoFold" :class="{fold:!isAreeInfoFold}" src="@/assets/svg/ic-arrow-right-yellow.svg" /> -->
				</p> 
				<div class="agree-content">
					<span>수집 목적: 서비스 및 제품 소개, 고객문의 상담 등</span>
					<span>수집 항목: 기관명, 이메일 주소, 연락처 등</span>
					<span>보유 기간: 문의일로부터 3개월 보관 후 즉시 파기</span>
				</div>
			</div>
			<button class="submit" @click="submit">견적서 다운로드</button>
		</div>
	</b-modal>
</template>

<script>
import { sendMessage } from '@/services/slack.js'
    export default {
        name: '',
        props: {},
        watch: {
            count(val) {
                this.period = val
            },
            licenseType(val) {
                this.estimateInfo.type = val
                if(val == 'EDUCATION') this.selectedPeriod = 'YEAR'
            },
        },
        computed: {
            count() {
                return this.selectedPeriod == 'YEAR' ? 12 : 1
            },
			tooltipText() {
				return this.licenseType == 'EDUCATION' ? '대학 무제한 요금은<br>연간결제만 가능!' : ''
			},
        },
        created() {
            this.init()
        },
        data() {
            return {
                estimateInfo: {},
                valid: true,
                accountCount: 0,
                selectedPeriod: '',
                period: 0,
                licenseType: '',
                licenseList: [
                    {
                        name: 'STANDARD',
                        value: 'STANDARD',
                    }, 
                    {
                        name: '대학 무제한',
                        value: 'EDUCATION'
                    }
                ],
				isAgree: false,
            }
        },
        methods: {
            init() {
                this.estimateInfo = {}
                this.valid = true
                this.accountCount = 1
                this.selectedPeriod = 'YEAR'
				this.licenseType = ''
            },
            minusAccount() {
                if(this.accountCount > 1) this.accountCount -= 1
            },
            plusAccount() {
                if(this.licenseType != 'EDUCATION') this.accountCount += 1
            },
            minusPeriod() {
                if(this.period > this.count) this.period -= this.count
            },
            plusPeriod() {
                this.period += this.count
            },
            changeOption(index) {
                this.licenseType = this.licenseList[index].value
            },
            type(value) {
                return this.licenseList.find(it => it.value == value).name
            },
            async submit() {
                if(!this.isValid()) return false
				const data = {
					...this.estimateInfo,
					count: this.estimateInfo.type == 'STANDARD' ? this.accountCount : 99999,
					plan: this.selectedPeriod,
					month: this.period
				}
				await sendMessage(data, '견적서 다운로드')
                const routeData = this.$router.resolve({name: 'Estimate', query: data})
                window.open(routeData.href, '_blank')
            },
			// validation
			isValid() {
                try {
                    this.valid = true
                    if(!this.estimateInfo.instituteName || !this.estimateInfo.email || !this.estimateInfo.phone || !this.estimateInfo.type) {
                        this.valid = false
                        throw 'inValid'
                    }
					if(!this.emailValidation(this.estimateInfo.email)) {
						this.valid = false
						throw 'email'
					}
					if(!this.phoneValidation(this.estimateInfo.phone)) {
						this.valid = false
						throw 'phone'
					}
					if(!this.isAgree) {
						this.valid = false
						throw 'agree'
					}
                    return true
                } catch(e) {
					if(e == 'email') {
						alert('이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
						return false
					} else if(e == 'phone') {
						alert('연락처가 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
						return false
					} else if(e == 'agree') {
						alert('개인정보 수집 및 이용에 동의해주세요.')
						return false
					} else return false
                }
            },
			phoneValidation(val) {
				return !/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(val) ? false : true
			},
			emailValidation(val) {
				const emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
				return !emailRule.test(val) ? false : true
			},
			autoHypen(str) {
				str = str.replace(/[^0-9]/g, '')
				let tmp = ''

				if (str.substring(0, 2) == '02') {
					// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
					if (str.length < 3) {
						return str
					} else if (str.length < 6) {
						tmp += str.substr(0, 2)
						tmp += '-'
						tmp += str.substr(2)
						return tmp
					} else if (str.length < 10) {
						tmp += str.substr(0, 2)
						tmp += '-'
						tmp += str.substr(2, 3)
						tmp += '-'
						tmp += str.substr(5)
						return tmp
					} else {
						tmp += str.substr(0, 2)
						tmp += '-'
						tmp += str.substr(2, 4)
						tmp += '-'
						tmp += str.substr(6, 4)
						return tmp
					}
				} else {
					// 핸드폰 및 다른 지역 전화번호 일 경우
					if (str.length < 4) {
						return str
					} else if (str.length < 7) {
						tmp += str.substr(0, 3)
						tmp += '-'
						tmp += str.substr(3)
						return tmp
					} else if (str.length < 11) {
						tmp += str.substr(0, 3)
						tmp += '-'
						tmp += str.substr(3, 3)
						tmp += '-'
						tmp += str.substr(6)
						return tmp
					} else {
						tmp += str.substr(0, 3)
						tmp += '-'
						tmp += str.substr(3, 4)
						tmp += '-'
						tmp += str.substr(7)
						return tmp
					}
				}
			},
			setPhoneForm(e) {
				const phoneNumber = this.autoHypen(e.target.value)
				this.estimateInfo.phone = phoneNumber
			},
        },
    }
</script>

<style lang="scss" scoped>
.tooltip {
    top: 25px;
    &.left {
        left: -15px;
    }
}
input {
	&:focus, &:active, &:hover {
		border: 0.8px solid #444 !important;
		box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.15);
	}
}
::v-deep .dropdown-toggle {
    font-size: 14px;
}
::v-deep {
	.modal-header {
		padding-top: 25px !important;
	}
	.dropdown {
		height: 100%;
		width: 100%;
	}
	.option-wrapper {
		background-image: url(~@/assets/svg/arrow-down-ccc.svg);
		background-repeat: no-repeat;
		background-size: 24px;
		background-position: center right 8px;
		border: 1px solid #ccc;
		border-radius: 5px;
		justify-content: left;
		width: 100%;
		height: 100%;
		background-color: transparent;
		padding-left: 10px;
		text-align: left;
		text-decoration: none;
        color: #111;
		&:hover,
		&:active,
		&:focus {
			color: #111;
			border: 0.8px solid #444;
			box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.15);
            text-decoration: none;
		}
		b {
			margin-right: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			max-width: 90px;
		}
		p {
			max-width: 90%;
			min-width: 90px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-weight: 400;
		}
		span {
			color: #999;
		}
		&.error {
			border: 0.8px solid #ff3b31;
		}
	}
	ul {
		width: 100%;
		overflow-y: auto;
		max-height: 200px;
		padding: 5px;
		li {
			padding-left: 10px;
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			width: 100%;
			height: 36px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: #f5f5f5;
				border-radius: 4px;
			}
			a {
				display: flex;
				padding: 0px;
				width: 100%;
				height: 100%;
				align-items: center;
				&:hover {
					background-color: transparent;
				}
				&:active {
					color: #111;
				}
				b {
					margin-right: 10px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 90px;
				}
				p {
					max-width: 100%;
					min-width: 90px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}
}
.period-wrap {
    display: flex;
    gap: 20px;
    .input-div {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #111;
		& > label {
			margin-bottom: 0;
		}
        &.readonly {
            color: #ccc;
        }
        input[type='radio'] {
            width: 0;
            height: 0;
            position: relative;
            margin-right: 25px;
            margin-left: 0px;
            margin-top: -14px;
            cursor: pointer;
            &:before {
                width: 20px;
                height: 20px;
                background-color: #fff;
                content: url(~@/assets/svg/radio-off.svg);
                position: absolute;
                top: 0;
                left: 0;
            }
            &:checked {
                &:before {
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    content: url(~@/assets/svg/radio-on.svg);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
    .num-box {
        width: 190px;
        margin-left: auto;
    }
}

::v-deep .modal-header {
    border-bottom: none;
    padding: 1rem 2rem 0;
}
.modal-title {
    p {
        font-size: 14px;
    }
}
.modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.submit {
    border-radius: 5px;
    background:#fec741;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    font-weight: 700;
    width: 200px;
    margin: 30px auto 0;
}
.required {
	display: inline-block;
	color: #ff3b31;
}
.input-wrapper {
		width: 100%;
		& > p {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 8px !important;
		}
		input {
			width: 100%;
			border-radius: 5px;
			font-size: 14px;
			font-weight: 400;
			padding-left: 10px;
			height: 30px;
			border: 1px solid #ccc;
		}
		&.error {
			input {
				border-color: #ff3b31;
			}
			> span {
				display: block;
			}
		}
		> span {
			font-size: 0.75rem;
			color: #ff3b31;
			font-weight: 400;
			position: relative;
			height: 17px;
			display: none;
			padding-left: 16px;
			padding-top: 2px;
			&:before {
				position: absolute;
				width: 12px;
				height: 12px;
				content: url(~@/assets/svg/noti-error.svg);
				top: 4px;
				left: 0;
			}
		}
	}

.num-box {
	width: 160px;
	display: flex;
	justify-content: space-between;
	height: 30px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 5px;
	align-items: center;
	.license-count-unit {
		text-align: left;
		font-size: 14px;
	}
	button {
	    padding: 0;
	    border-radius: 6px;
	    width: 20px;
	    height: 20px;
		border: 0;
		border: 1px solid #fff;
		box-sizing: border-box;
		img {
			width: inherit;
			height: inherit;
			padding: 0;
			margin: 0;
		}
		&.cant,
		&:disabled {
			border: 0 !important;
			img {
				opacity: 0.2;
			}
			&:hover {
				img {
					opacity: 0.2;
				}
			}
		}
		&:hover {
			img {
				opacity: 0.95;
			}
		}
	}
}
.require-info {
		margin-top: 30px;
		width: 100%;
		.agree-box {
			justify-content: space-between;
			display: flex;
			align-items: center;
			img {
				cursor: pointer;
				width: 20px;
			}
			.arrow {
				transition: 0.3s all;
				transform: rotate(90deg);
				&.fold {
					transform: rotate(0);
				}
			}
		}
		label {
			display: flex;
			align-items: center;
			gap: 10px;
			font-weight: 600;
			font-size: 14px;
			line-height: 130%;
			cursor: pointer;
			margin-bottom: 0;
		}
		.agree-content {
			margin-top: 10px;
			border-radius: 5px;
			border: 0.8px solid #d3d3d3;
			padding: 10px 15px;
			display: flex;
			flex-direction: column;
			& > span {
				color: #999;
				font-size: 12px;

			}
		}
}
</style>