<template>
	<div class="blog">
		<div class="intro">
			<h1>{{ tt('연구에 필요한 모든 관리,') }}</h1>
			<b>{{ tt('랩매니저 PRO') }}</b>
		</div>
		<div class="tab">
			<blog-tab :list="tabList" @change="onChangeTab" :activeTab="activeTab" />
		</div>
		<div class="list-wrap">
			<card-list
				v-for="(key, index) in showComponents"
				:key="`card-list${index}`"
				:title="setTitle(key)"
				:list="list[key]"
				:count="count[key]"
				:length="setLength(key)"
				:isLoading="isLoading"
				:activeValue="activeTab.value"
				@click="routeDetail(key, $event)"
				@expand="onChangeTab(key)"
				@page="changePage(key, $event)"
			/>
			<document-list
				v-show="activeTab.value == 'all'"
				title="NEWS"
				:list="list['news']"
				:count="count['news']"
				:showAll="false"
				:isLoading="isLoading"
				@page="changePage('news', $event)"
				@click="onClickNewsItem"
			/>
		</div>
	</div>
</template>

<script>
import BlogTab from '@/ui/page/BlogTab.vue'
import CardList from '@/ui/page/CardList.vue'
import DocumentList from '@/ui/page/DocumentList.vue'

export default {
	name: 'Blog',
	components: {
		BlogTab,
		CardList,
		DocumentList,
	},
	metaInfo: {
		title: '블로그_랩매니저 PRO',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인' },
			{vmid:'description', name:'description', content: '랩매니저 PRO 공식 블로그, 공지사항, 고객사 인터뷰, 랩매니저 PRO, NEWS' },
			{vmid:'og:description', property:'description', content: '랩매니저 PRO 공식 블로그, 공지사항, 고객사 인터뷰, 랩매니저 PRO, NEWS' },
		]
	},
	created() {
		this.activeTab = this.tabList.filter(tab => tab.value == this.$route.params.category)[0]
		if (!this.$root.isPc) {
			this.cardLength = 5
		}
	},
	mounted() {
		this.showComponents = JSON.parse(JSON.stringify(this.pages))
		this.changePage('news', 1)
	},
	data() {
		return {
			activeTab: {},
			tabList: [
				{ name: '전체 보기', value: 'all' },
				{ name: '공지사항', value: 'notice' },
				{ name: '고객사 인터뷰', value: 'interview' },
				{ name: '랩매니저PRO', value: 'lmpro' },
			],
			cardLength: 9,
			documentLength: 5,
			pages: ['notice', 'interview', 'lmpro'],
			showComponents: [],
			list: {
				notice: [],
				interview: [],
				lmpro: [],
				news: [],
			},
			count: {},
			offset: {
				notice: 0,
				interview: 0,
			},
			isLoading: false,
		}
	},
	watch: {
		activeTab: {
			deep: true,
			handler(tab) {
				if (tab.value == 'all') {
					this.showComponents = this.pages
					this.changePage('news', 1)
				} else {
					this.showComponents = [tab.value]
				}
				window.scrollTo(0, 0)
			},
		},
		showComponents(list) {
			for (let item of list) {
				this.changePage(item, 1)
			}
		},
	},
	methods: {
		setLength(category) {
			if (category == 'news') {
				return this.documentLength
			} else if (this.activeTab.value == 'all') {
				return 3
			} else {
				return this.cardLength
			}
		},
		setTitle(category) {
			let title = ''
			this.tabList.forEach(tab => {
				if (tab.value == category) {
					title = tab.name
				}
			})
			return title
		},
		initSetting(category) {
			//skeleton 추가
			this.isLoading = true
			for (let i = 0; i <= 2; i++) {
				this.list[category].push({ category: 'all' })
			}
		},
		async fetchBoardList(category, offset) {
			await this.initSetting(category)
			const response = await this.$service.client.fetchBoardList(category, offset, this.setLength(category))
			this.list[category] = response.list
			this.count[category] = response.count
			this.isLoading = false
		},
		onChangeTab(tab) {
			this.activeTab = typeof tab == 'string' ? this.tabList.filter(el => el.value == tab)[0] : tab
			this.$router.push({
				name: 'Blog',
				params: {
					category: this.activeTab.value,
				},
			})
		},
		async changePage(category, page) {
			this.offset[category] = (page - 1) * this.setLength(category)
			await this.fetchBoardList(category, this.offset[category])
		},
		routeDetail(category, id) {
			this.$router.push({
				name: 'BlogDetail',
				params: {
					category,
					id,
				},
			})
		},
		onClickNewsItem(url) {
			window.open(url)
		},
	},
}
</script>

<style lang="scss" scoped>
.blog {
	.intro {
		width: 100%;
		background-color: #fffcf4;
		padding: 120px 0 80px;
		text-align: center;
		flex-wrap: wrap;
		margin-top: -100px;
		h1,
		b {
			font-size: 2.25rem;
			letter-spacing: -0.022em;
		}
		b {
			font-weight: 700;
		}
	}
	.blog-tab {
		margin: 30px 0;
	}
	.list-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 600px;
	}
}

@media (max-width: 767px) {
	.blog {
		.intro {
			text-align: center;
			padding-top: 160px;
			padding-bottom: 61px;
			h1,
			b {
				font-size: 1.75rem;
				word-break: keep-all;
			}
			b {
				font-weight: 800;
			}
		}
		.blog-tab {
			margin: 36px 8px;
		}
	}
}
</style>
