<template>
    <div class="free-layout">
        <div class="free-button-layout">
            <h1>무료로 먼저 시작해 보고 결정하셔도 좋습니다.<br />지금, 바로 랩매니저 PRO를 시작해 보세요!</h1>
            <button @click="$root.openApp()">
                무료사용
            </button>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.free-layout {
    width: 100%;
    background-color: #fef9ec;
    padding: 90px 0px;
    .free-button-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        margin: 0 auto;
        height: 400px;
        background-color: rgba(254, 199, 65, 0.9);
        border-radius: 30px;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
        }
        button {
            color:black;
            padding: 10px 80px;
            font-weight: 700;
            font-size: 25px;
            margin-top: 80px;
            background-color: white;
            border-radius: 30px;
        }
    }
}
@media (max-width: 767px) {
.free-layout {
    width: 100%;
    background-color: #fef9ec;
    padding: 60px 13px;
    .free-button-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        height: 300px;
        background-color: rgba(254, 199, 65, 0.9);
        border-radius: 30px;
        padding: 30px;
        h1 {
            word-break: keep-all;
            text-align: center;
            font-weight: 700;
            font-size: 28px;
            line-height: 129.99%;
        }
        button {
            padding: 10px 20px;
            font-weight: 700;
            font-size: 18px;
            margin-top: 40px;
            background-color: white;
            border-radius: 30px;
        }
    }
}

}
</style>