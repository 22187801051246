<template>
	<b-modal id="inquiry-form" ref="modal" size="md" centered hide-footer @show="init" @hidden="init">
		<div class="header-box">
			<b v-text="`연구실 관리의 모든 것, 랩매니저 PRO`"></b>
			<p v-html="`도입 문의를 남겨주시면, <br>담당 매니저가 빠른 시일내에 연락 드리겠습니다.`"></p>
		</div>
		<div class="modal-body">
			<h6>무료 신청하기</h6>
			<div class="scrollable">
				<div class="form-box">
					<form-input title="기관명" :required="true" v-model="institution" />
					<form-input title="이름" :required="true" v-model="manager" />
					<form-input title="전화번호" :required="true" mode="phone" v-model="phone" content="연락 받으실 전화번호를 입력하세요." />
					<form-input title="이메일" :required="true" content="기업용 이메일 주소를 입력하세요." v-model="email" />
				</div>
				<div class="flex check-box">
					<input id="privacy-agree" type="checkbox" v-model="agree" />
					<label for="privacy-agree" v-text="'(필수) 개인정보 수집 이용에 관한 동의'" />
				</div>
				<span class="noti">*입력한 내용이 사실과 다를 경우 이벤트 당첨에서 제외될 수 있습니다.</span>
			</div>
			<button class="submit" @click="submit">신청하기</button>
		</div>
	</b-modal>
</template>

<script>
import FormInput from '@/ui/form/FormInput'

export default {
	name: 'ModalGatheringForm',
	components: {
		FormInput,
	},
	data() {
		return {
			institution: '',
			email: '',
			manager: '',
			phone: '',
			agree: false,
		}
	},
	methods: {
		init() {
			this.institution = ''
			this.email = ''
			this.manager = ''
			this.phone = ''
			this.agree = false
		},
		validation() {
			let isValid = true
			if (this.institution == '') {
				isValid = false
				return alert('기관명을 입력해주세요')
			} else if (this.manager == '') {
				isValid = false
				return alert('이름을 입력해주세요')
			} else if (this.phone == '') {
				isValid = false
				return '연락처를 입력해주세요'
			} else if (this.phone && this.phone.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.phone)) {
					isValid = false
					return alert('연락처가 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
				}
			}
			let emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
			if (this.email === '') {
				isValid = false
				return alert('이메일 주소를 입력해주세요.')
			} else if (!emailRule.test(this.email)) {
				isValid = false
				return alert('이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
			}

			if (!this.agree) {
				isValid = false
				return alert('개인정보 수집에 동의해주세요')
			}

			return isValid
		},
		async submit() {
			if (!this.validation()) {
				return false
			}

			try {
				await this.$service.client.submitInquiry({
					institution: this.institution,
					manager: this.manager,
					phone: this.phone,
					email: this.email,
					type: 'landing-phone',
				})
				alert('신청이 완료 되었습니다.\n빠른 시일내로 연락 드리겠습니다.')
				await this.$refs['modal'].hide()
			} catch (e) {
				alert('신청하던 중 오류가 발생했습니다\n잠시 후 다시 시도해주세요')
			}
			this.init()
		},
	},
}
</script>

<style lang="scss">
#inquiry-form {
	.modal-header {
		border-bottom: 0;
		z-index: 10;
		width: 100%;
	}

	.header-box {
		width: 100%;
		background: linear-gradient(315deg, #fec741 0%, #fed671 100%);
		margin-top: -56px;
		padding-top: 50px;
		padding-bottom: 22px;
		border-radius: 0.3rem 0.3rem 0 0;
		text-align: center;
		b {
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 8px;
			display: block;
		}
		p {
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.6;
		}
	}

	.modal-body {
		padding: 0 0;
		.scrollable {
			max-height: 50vh;
			overflow-y: auto;
		}
		h6 {
			width: 100%;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			font-weight: 600;
			border-bottom: 1px solid #eee;
			margin-bottom: 26px;
		}
	}

	.flex {
		padding-left: 15px;
		margin: 1rem 0 20px;
		&.check-box {
			align-items: center;
			justify-content: center;
			padding: 0 0;
		}
		label {
			margin: 0 0;
			display: inline-block;
			font-size: 0.875rem;
			font-weight: 400;
			color: #fec741;
		}
	}
	input[type='checkbox'] {
		width: 18px;
		height: 18px;
		position: relative;
		margin-right: 10px;
		cursor: pointer;
		&:before {
			width: 20px;
			height: 20px;
			position: absolute;
			top: -1px;
			left: -1px;
			content: '';
			background-color: white;
		}
		&:after {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			background-image: url(~@/assets/svg/gathering/check-off-y.svg);
			background-size: 100%;
			background-position: center;
		}
		&:checked {
			&:after {
				background-image: url(~@/assets/svg/gathering/check-on-y.svg);
			}
		}
	}
	.noti {
		display: block;
		margin: 25px 0;
		font-size: 0.675rem;
		font-weight: 400;
		text-align: center;
	}
	.submit {
		width: 100%;
		height: 56px;
		border-top: 1px solid #eee;
		border-radius: 0 0 0.3rem 0.3rem;
		font-size: 1rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
