<template>
    <div ref="wrapper" class="swiper-wrap">
        <slot name="index">
        </slot>
        <p v-if="title">{{ title }}</p>
        <swiper ref="swiper" @slideChange="slideChanged" :options="options" :freeMode="true">
            <swiperSlide v-for="(item, index) in list" :key="index" :class="{first: index == 0 || index == 1}">
                <div class="content_wrapper" :style="{width: `${width}px`}" style="display: flex; gap: 30px" @click="e => $emit('click',e )">
                    <div v-if="item.content" class="content_wrap" style="display: flex; flex-direction: column" v-html="item.content">
                    </div>
				    <img v-for="(img, index) in item.images" :key="`img_${index}`" :src="img" />
                </div>
            </swiperSlide>
            <div v-if="options.isNavigation" class="swiper-button-next" slot="button-next"></div>
            <div v-if="options.isNavigation" class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-pagination" slot="pagination" />
        </swiper>
    </div>
</template>

<script>
import {swiper, swiperSlide} from 'vue-awesome-swiper'
export default {
    components: {
        swiper,
        swiperSlide,
    },
    props: {
        list: {
            type: Array,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        options: {
            type: Object,
            default: () => {},
        },
        // isNavigation: {
        //     type: Boolean,
        //     default: false,
        // }
    },
    watch: {
        list(){
			this.$refs.swiper.swiper.activeIndex = 0
		},
    },
    mounted() {
        const perPage = this.options?.slidesPerView ? this.options.slidesPerView : 1
		this.width = (this.$refs['wrapper'].offsetWidth - 40 - 30) / perPage
	},
    data(){
        return {
			width: 0,
        }
    },
    methods: {
        slideChanged() {
            this.$emit('actionSlide', this.$refs.swiper.swiper.realIndex)
        },
    },
    
}
</script>

<style lang="scss" scoped>
.swiper-wrap {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .swiper-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        // overflow: visible;
        .swiper-button-next {
            // transform:translateX(200%);
            background-size: 43px 86px;
            width: 43px;
            height: 86px;
            filter: brightness(0);
            top: 35%;
        }
        .swiper-button-prev {
            // transform:translateX(-200%);
            background-size: 43px 86px;
            width: 43px;
            height: 86px;
            filter: brightness(0);
            top: 35%;
        }
        .swiper-wrapper {
            .swiper-slide{
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: center;
                justify-content: center;
                align-items: center;
            }
        }
        .swiper-pagination {
            position: relative;
            margin-top: 30px;
        }
    }
}
::v-deep .swiper-pagination-bullet {
    opacity: 1;
    margin: 0 10px !important;
    width: 12px;
    height: 12px;
    background: #efefef !important;
}

::v-deep .swiper-pagination-bullet-active {
    background: #000 !important;
}
</style>