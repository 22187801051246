<template>
	<label class="switch">
		<input type="checkbox" v-model="checkedValue" @change="changeValue" :disabled="disabled" />
		<span class="slider round"></span>
	</label>
</template>
<script>
export default {
	props: {
		checked: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			checkedValue: false,
		}
	},
	watch: {
		value() {
			this.checkedValue = this.value
		},
		checked() {
			this.checkedValue = this.checked
		},
	},
	mounted() {
		this.checkedValue = this.checked || this.value
	},
	methods: {
		changeValue() {
			this.$emit('input', this.checkedValue)
			this.$emit('change', this.checkedValue)
		},
	},
}
</script>

<style lang="scss" scoped>
.switch {
	position: relative;
	display: inline-block;
	width: 65px;
	max-width: 65px;
	min-width: 65px;
	height: 34px;
	max-height: 34px;
	margin-bottom: 0px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
	visibility: hidden;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #cacbcb;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	display: inline-block;
	box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.15);
}

.slider:before {
	position: absolute;
	content: '';
	height: 28px;
	width: 28px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	display: inline-block;
	left:3px;
	top:3px;
}
.switch {
	input:checked + .slider {
		background-color: #FEC741;
	}

	input:focus + .slider {
		box-shadow: 0 0 1px #FEC741;
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(30px);
		-ms-transform: translateX(30px);
		transform: translateX(30px);
	}
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
</style>
