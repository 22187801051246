<template>
	<div class="pagination">
		<button class="prev" @click="changePage(current - 1)" :disabled="current <= pageCount">
			<img src="@/assets/svg/pagination-arrow.svg" />
		</button>
		<button
			@click="changePage(page)"
			class="page-item"
			v-for="(page, index) in pagination"
			:key="`page_${index}`"
			:class="{ active: page === current }"
		>
			{{ page }}
		</button>
		<button class="next" @click="changePage(current + 1)" :disabled="current >= totalPage">
			<img src="@/assets/svg/pagination-arrow.svg" />
		</button>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		total: {
			type: Number,
			default: 0,
		},
		pageCount: {
			type: Number,
			default: 10,
		},
	},
	async mounted() {
		this.changePage(0)
	},
	data() {
		return {
			pagination: [],
			current: 1,
			totalPage: 1,
			max: 1,
		}
	},
	watch: {
		total() {
			this.changePage(0)
		},
	},
	methods: {
		async changePage(page) {
			let first = 1
			this.pagination = []
			this.totalPage = Math.ceil(this.total / this.pageCount)
			await this.currentPage(page)

			if (this.current > this.pageCount) {
				first = Number(`${Math.floor((this.current - 1) / this.pageCount)}1`)
			}

			this.max = first + 9 < this.totalPage ? first + 9 : this.totalPage

			for (let i = first; i <= this.max; i++) {
				this.pagination.push(i)
			}
			this.$emit('page', this.current)
		},
		currentPage(page) {
			if (page == 0) {
				this.current = 1
			} else if (page < this.totalPage) {
				this.current = page
			} else {
				this.current = this.totalPage
			}
		},
	},
}
</script>

<style lang="scss">
.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	> button {
		width: 30px;
		height: 30px;
		box-sizing: border-box;
		border: 1px solid #f2f3f4;
		border-left: 0;
		font-size: 0.875rem;
		&.prev {
			transform: rotate(180deg);
		}
		&.prev {
			border-left: 1px solid #f2f3f4;
		}
		&:disabled {
			background-color: rgba(242, 243, 244, 0.2);
			img {
				opacity: 0.4;
			}
		}
		&.active {
			background-color: rgba(254, 199, 65, 0.2);
			color: #ffb000;
			font-weight: 700;
		}
	}
}
</style>
