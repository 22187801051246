<template>
    <div class="base-list">
        <div class="header">
            <div>
                <b>{{ tt(title) }}</b>
                <p v-if="subTitle">{{ tt(subTitle) }}</p>
            </div>
            <span v-if="showAll" @click="$emit('expand')">{{ tt('더보기') }} ></span>
        </div>
        <div class="section">
            <slot></slot>
        </div>
        <div v-if="!showAll && totalCount > 0" class="footer">
            <pagination :total="totalCount" :pageCount="pageCount" @page="$emit('page', $event)" />
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'

export default {
    name: 'BaseList',
    components: {
        Pagination,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
        showAll: {
            type: Boolean,
            default: true,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        pageCount: {
            type: Number,
            default: 10,
        },
    },
    data() {
        return {}
    },
    watch: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
.base-list {
    display: flex;
    flex-direction: column;
    gap: 35px;
    overflow: hidden;
    width: 1280px;
    margin-bottom: 60px;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        div {
            display: flex;
            align-items: center;
            gap: 30px;
            b {
                font-weight: 700;
                font-size: 1.5rem;
            }
            p {
                font-size: 1rem;
                color: #666;
            }
        }
        span {
            font-size: 1rem;
            color: #666;
            cursor: pointer;
        }
    }
}
@media (max-width: 767px) {
    .base-list {
        width: 100%;
        padding: 0 5%;
    }
}
</style>
