<template>
	<div class="promotion-wrapper">
		<img src="@/assets/image/campaign01/image-01.png" />
		<div class="promotion-footer">
			<a href="#" @click="$root.openApp">무료사용</a>
			<a href="https://www.lab-manager.com/request">문의하기</a>
		</div>
		<div class="function-wrapper">
			<h1>
				<img class="logo" src="@/assets/image/logo-lmp.svg" />를 몰랐던<br/>연구원들의 하루
			</h1>
			<Carousel :list="carousel1" title="신입 연구원" noControl noIndicator>
				<template #index>
					<span style="font-size: 15px; font-weight: 700; color:#333; margin-bottom: 5px;">#01</span>
				</template>
			</Carousel>
			<Carousel :list="carousel2" title="선임 연구원" noControl noIndicator>
				<template #index>
					<span style="font-size: 15px; font-weight: 700; color:#333; margin-bottom: 5px;">#02</span>
				</template>
			</Carousel>
		</div>
		<img src="@/assets/image/campaign01/image-02.png" />
		<img src="@/assets/image/campaign01/image-03.png" />
		<img src="@/assets/image/campaign01/image-04.png" />
		<div class="refer-wrapper">
			<h1>
				<img src="@/assets/image/campaign01/image-refer-title.png" />
			</h1>
			<div class="refer-tab">
				<div class="tab" :class="{active: tab == 'education'}" @click="tab = 'education'">대학교</div>
				<div class="tab" :class="{active: tab == 'enterprise'}" @click="tab = 'enterprise'">기업</div>
			</div>
			<Carousel :list="tab == 'education' ? education : enterprise" :perPage="1">
			</Carousel>
		</div>
	</div>
</template>
<script>
import Carousel from '@/components/Carousel2.vue';

export default {
	computed: {
		carousel1() {
			return [
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(후배 Ver)_01.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(후배 Ver)_02.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(후배 Ver)_03.png"),
					]
				},
			];
		},
		carousel2() {
			return [
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(선배 Ver)_01.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(선배 Ver)_02.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/캐러셀(선배 Ver)_03.png"),
					]
				},
			];
		},
		education() {
			return [
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_대학교01.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_대학교02.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_대학교03.png"),
					]
				},
			];
		},
		enterprise() {
			return [
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업01.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업02.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업03.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업04.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업05.png"),
					]
				},
				{
					images: [
						require("@/assets/image/campaign01/레퍼런스_기업06.png"),
					]
				},
			];
		}
	},
	data() {
		return {
			tab: 'education'
		}
	},
	components: { 
		Carousel,
	},
}
</script>
<style lang="scss" scoped>
.promotion-wrapper {
	width: 450px;
	margin: auto;

	&>img {
		width: 100%;
	}

	.promotion-footer {
		z-index: 20;
		display: flex;
		justify-content: center;
		position: fixed;
		gap: 10px;
		bottom: 0px;
		width: 450px;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: calc(50% - 5px);
			height: 60px;
			background: #f4f4f4;
			border: 1px solid black;
			border-radius: 10px 10px 0px 0px;
			font-style: normal;
			font-weight: 900;
			font-size: 22px;
			line-height: 129.99%;
			color: black;
		}
	}
}

::v-deep .VueCarousel-slide{
	img {
		width: 100%;
	}
}
.function-wrapper, .refer-wrapper {
	padding-top: 45px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #FEF9EC;
	h1 {
		.logo {
			width: 200px;
			margin-right: 8px;
			vertical-align: baseline;
		}
		margin-top: 10px;
		font-style: normal;
		font-weight: 800;
		font-size: 28px;
		line-height: 129.99%;
		/* or 36px */

		text-align: center;

		color: #000000
	}
}
.refer-wrapper {
	padding-top: 60px;
	padding-bottom: 60px;
	background-color: white;
	h1 {
		img {
			width: 230px;
		}
	}
}
@media (max-width: 767px) {
	.promotion-wrapper {
		width: 100%;

		.promotion-footer {
			width: 100%;
		}
	}
}
.refer-tab{
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
	.tab {
		position: relative;
		font-size: 15px;
		font-weight: 700;	
		color: #cacbcb;
		&.active {
			color: #000;
		}
		&::after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0px;
			width: 100%;
			height: 3px;
			background-color: #cacbcb;
			border-radius: 2px;
			
		}
		&.active::after {
			background-color: #fec741;
		}
	}
}

</style>