<template>
	<div class="b-skeleton b-skeleton-text b-skeleton-animate-wave" :style="`width: ${width}; height: ${height}`" :class="`${size}`"></div>
</template>

<script>
export default {
	name: 'Skeleton',
	props: {
		width: {
			type: String,
			default: '100%',
		},
		height: {
			type: String,
			default: '1em',
		},
		size: {
			type: String,
			default: 'md',
		},
	},
}
</script>

<style lang="scss">
//bootstrapVue
.b-skeleton {
	position: relative;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.12);
	cursor: wait;
	mask-image: radial-gradient(#fff, #000);
	&:before {
		content: ' ';
	}
}
.b-skeleton-text {
	height: 1rem;
	margin-bottom: 0.25rem;
	border-radius: 0.25rem;
	&.sm {
		height: 0.75rem;
	}
}
.b-skeleton-animate-wave:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 0;
	background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);
	animation: b-skeleton-animate-wave 1.75s linear infinite;
}
</style>
