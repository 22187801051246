<template>
	<div class="floating-btns">
		<!-- <button class="kakao-channel" @click="goChannel">
			<img src="@/assets/image/bt-kakao-small@3x.png" />
		</button> -->
		<!-- <button class="go-top" @click="goTop">
			<img src="@/assets/svg/go-top.svg" />
		</button> -->
		<button v-if="!isPromotionM10" class="go-merck" @click="goMerck">
			<img src="@/assets/image/icon-merck-white.svg" />
			<div>할인안내</div>
		</button>
		<button v-if="isPromotionM10" class="go-main" @click="goMain">
			<img src="@/assets/image/icon-floating-lab.svg" />
			<div>메인</div>
		</button>
	</div>
</template>

<script>
export default {
	computed :{
		isPromotionM10(){
			return this.$route.name == 'PromotionM10'
		}
	},
	methods: {
		goChannel() {
			window.open('http://pf.kakao.com/_aCNLb/chat')
		},
		goTop() {
			window.scrollTo(0, 0)
		},
		goMerck() {
			this.$router.push({name : 'PromotionM10'})
		},
		goMain(){
			this.$router.push({name: 'Home'})
		}
	},
}
</script>

<style lang="scss" scoped>
.floating-btns {
	position: fixed;
	bottom: 80px;
	right: 24px;
	z-index: 2;
	button {
		width: 60px;
		height: 60px;
		border-radius: 20%;
		background-color: transparent;
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15);
		padding: 0;
		margin: 0;
		display: block;
		img {
			width: inherit;
			height: inherit;
		}
	}
	.kakao-channel {
		margin-bottom: 20px;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.go-merck, .go-main {
		background-color: #503291;
		border-radius: 24px;
		line-height: 16px;
		padding: 4px;
		img {
			width: 100%;
			height: unset;
		}
		div {
			font-size: 14px;
			color: white;
			font-weight: 800;
		}
	}
	.go-main {
		background-color: white;
		img {
			width: 60%;
		}
		div {
			margin-top: 2px;
			color: #444;
		}
	}
}

@media (max-width: 767px) {
	.floating-btns {
		right: 14px;
		bottom: 100px;
		button {
			width: 43px;
			height: 43px;
		}
		.go-merck, .go-main {
			background-color: #503291;
			border-radius: 15px;
			line-height: 12px;
			padding: 2px;
			img {
				width: 100%;
				height: unset;
			}
			div {
				font-size: 10px;
				color: white;
				font-weight: 800;
			}
		}
		.go-main {
			background-color: white;
			img {
				width: 60%;
			}
			div {
				margin-top: 2px;
				color: #444;
			}
		}
	}
}

</style>
