import cookies from 'vue-cookies'
import axios from 'axios'

// Module
import Client from './module/Client'

class Services {
	constructor() {
		this.axios = axios.create({
			baseURL: 'https://service.lab-manager.com',
			timeout: 100000,
		})

		this.client = new Client(this.axios, cookies)
	}
}

export default new Services()
