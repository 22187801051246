<template>
	<form class="formBox wrap" @submit.prevent="sendFeedback">
		<h4 v-if="title">{{ tt(title) }}</h4>
		<div class="inputBox" v-if="requiredBelong">
			<h6>{{ tt('소속 기관') }}</h6>
			<input type="text" :placeholder="tt('소속 기관을 선택해주세요')" v-model="belong" />
		</div>
		<div class="inputBox">
			<h6>
				{{ tt('이메일 주소') }}
				<span style="margin-left: 5px;" class="required">{{ tt('*필수') }}</span>
			</h6>
			<input type="text" :placeholder="tt('이메일 주소 오류가 빈번합니다. 정확한 주소를 남겨주세요.')" v-model="form.email" />
		</div>
		<div class="inputBox" v-if="requiredPhone">
			<h6>{{ tt('연락처') }}</h6>
			<input type="text" :placeholder="tt('연락처를 남겨주세요.')" v-model="form.phone" @input="setPhoneForm" />
		</div>
		<div class="inputBox" v-if="requiredName">
			<h6>{{ tt('성함') }}</h6>
			<input type="text" :placeholder="tt('문의 주시는 분의 성함을 남겨주세요.')" v-model="form.manager" />
		</div>
		<div class="inputBox" v-if="requiredContent">
			<h6>{{ tt('내용') }}</h6>
			<textarea :placeholder="tt('내용을 입력해주세요.')" v-model="form.content"></textarea>
		</div>
		<button type="button" @click="sendFeedback">
			{{ tt(btnText) }}
		</button>
	</form>
</template>

<script>
export default {
	name: 'RequestForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		requiredName: {
			type: Boolean,
			default: true,
		},
		requiredPhone: {
			type: Boolean,
			default: true,
		},
		requiredContent: {
			type: Boolean,
			default: true,
		},
		requiredBelong: {
			type: Boolean,
			default: false,
		},
		btnText: {
			type: String,
			default: '문의하기',
		},
	},
	components: {},
	data() {
		return {
			form: {
				email: '',
				manager: '',
				phone: '',
				content: '',
				belong: '',
			},
			content: '',
		}
	},
	methods: {
		async sendFeedback() {
			let emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i

			if (this.form.email === '') {
				return alert('이메일 주소를 입력해주세요.')
			} else if (!emailRule.test(this.form.email)) {
				return alert('이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
			}

			//휴대폰입력이 필요한 경우 + 휴대폰을 제대로 입력하지 않았을 경우
			if (this.requiredPhone && this.form.phone.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.form.phone)) {
					return alert('연락처가 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
				}
			}

			//소속기관을 입력하는 경우
			if (this.requiredBelong) {
				this.form.type = 'company'
				if (!this.form.belong) {
					return alert('소속 기업/기관/교육기관을 입력해주세요.')
				}
			}

			try {
				await this.$service.client.submitRequest(this.form)
				window.marketingEvent('conversion')
				alert('문의가 접수되었습니다.\n빠른 시일 내에 답변 드리도록하겠습니다.')
				this.form = {
					email: '',
					phone: '',
					manager: '',
					content: '',
					belong: '',
				}
			} catch (e) {
				alert('오류로 인해 문의가 접수되지 않았습니다.\n다시 한 번 시도해주세요')
			}
		},
		autoHypen(str) {
			str = str.replace(/[^0-9]/g, '')
			let tmp = ''

			if (str.substring(0, 2) == '02') {
				// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
				if (str.length < 3) {
					return str
				} else if (str.length < 6) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2)
					return tmp
				} else if (str.length < 10) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 3)
					tmp += '-'
					tmp += str.substr(5)
					return tmp
				} else {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 4)
					tmp += '-'
					tmp += str.substr(6, 4)
					return tmp
				}
			} else {
				// 핸드폰 및 다른 지역 전화번호 일 경우
				if (str.length < 4) {
					return str
				} else if (str.length < 7) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3)
					return tmp
				} else if (str.length < 11) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 3)
					tmp += '-'
					tmp += str.substr(6)
					return tmp
				} else {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 4)
					tmp += '-'
					tmp += str.substr(7)
					return tmp
				}
			}
		},
		setPhoneForm(e) {
			const phoneNumber = this.autoHypen(e.target.value)
			this.form.phone = phoneNumber
		},
	},
}
</script>

<style lang="scss" scoped>
.formBox {
	text-align: left;
	padding-top: 110px;
	position: relative;
	h4 {
		font-size: 36px;
		font-weight: 600;
	}
	.inputBox {
		padding-top: 40px;
		h6 {
			font-size: 18px;
			padding-bottom: 10px;
			span {
				font-size: 0.875rem;
				display: inline-block;
				color: #a0a0a0;
			}
			.required {
				color: #ff5746;
			}
		}
		input {
			width: 50%;
			border: 1px solid #c7c7c7;
			border-radius: 5px;
			height: 60px;
			padding-left: 10px;
		}
		select {
			width: 50%;
			height: 60px;
			border-radius: 5px;
			border: 1px solid #c7c7c7;
		}
		textarea {
			width: 100%;
			border-radius: 5px;
			border: 1px solid #c7c7c7;
			height: 400px;
			padding: 20px 15px;
		}
	}
	button {
		width: 50%;
		height: 60px;
		font-size: 18px;
		background-color: #fec741;
		border-radius: 5px;
		font-weight: 600;
		margin-top: 40px;
		margin-bottom: 134px;
	}
	.imgbox {
		width: 200px;
		position: absolute;
		bottom: 0;
		right: 0;
		img {
			width: 100%;
		}
	}
}

@media (min-width: 768px) and (max-width: 1139px) {
	.formBox {
		text-align: left;
		position: relative;
		padding: 110px 5% 0;
		h4 {
			font-size: 36px;
		}
		.inputBox {
			padding-top: 40px;
			h6 {
				font-size: 18px;
				padding-bottom: 10px;
			}
			input {
				width: 100%;
			}
			select {
				width: 100%;
			}
			textarea {
				width: 100%;
				border-radius: 5px;
				border: 1px solid #c7c7c7;
				height: 400px;
				padding: 20px 15px;
			}
		}
		button {
			width: 50%;
			height: 60px;
			font-size: 18px;
			background-color: #fec741;
			border-radius: 5px;
			font-family: AppleSDGothicNeoB;
			margin-top: 40px;
			margin-bottom: 134px;
		}
		.imgbox {
			width: 200px;
			position: absolute;
			bottom: 0;
			right: 0;
			img {
				width: 100%;
			}
		}
	}
}

@media (max-width: 767px) {
	.formBox {
		padding-top: 40px;
		h4 {
			font-size: 1.7rem;
			padding-bottom: 20px;
		}
		.inputBox {
			padding-top: 25px;
			h6 {
				font-size: 0.95rem;
				padding-bottom: 5px;
				span {
					font-size: 0.75rem;
				}
			}
			input {
				width: 100%;
				height: 40px;
				font-size: 0.9rem;
			}
			select {
				width: 100%;
				height: 40px;
				font-size: 0.9rem;
			}
			textarea {
				height: 20vh;
				font-size: 0.9rem;
				padding: 10px;
			}
		}
		button {
			height: 40px;
			font-size: 0.95rem;
			width: 100%;
			margin-top: 20px;
			margin-bottom: 50px;
		}
	}
}
</style>
