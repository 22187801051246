<template>
    <div class="content-wrapper">
        <div class="wrap">
            <h1>
                비대면 서비스 바우처 서비스<br/>왜 신청해야 할까요?
            </h1>
            <div class="explain-box">
                <img class="pc" src="@/assets/image/image-explain-1.png" />
                <img class="mo" src="@/assets/image/m-image-explain-1.png" />
                <p><span>1</span> 단, 금융기업 채무 불이행 중, 세금을 체납 중, 신청일 기준으로 현재 휴폐업 중, 유흥업종/무도주점업종/사행시설 및 관리 운영업종 및 기타 참여제한이 인정되는 경우 신청불가</p>
                <div class="bar" />
            </div>
            <div class="explain-box">
                <img class="pc" src="@/assets/image/image-explain-2.png" />
                <img class="mo" src="@/assets/image/m-image-explain-2.png" />
               <h5>K-비대면 바우처 신청 예시</h5>
               <div class="example-box">
                    <div class="l">
                        <img src="@/assets/image/logo-lmp.svg" />
                        <div class="price">
                            최대 2,000,000원
                        </div> 
                    </div>
                    <div class="l">
                        <div>타 서비스 프로그램</div>
                        <div class="price">
                            최대 2,000,000원
                        </div>
                    </div>
               </div>
               <div class="bar" />
            </div>
            <div class="explain-box">
                <img class="pc" src="@/assets/image/image-explain-3.png" />
                <img class="mo" src="@/assets/image/m-image-explain-3.png" />
                <div class="bar" />
            </div>
            <div class="explain-box">
                <img class="pc" src="@/assets/image/image-explain-4.png" />
                <img class="mo" src="@/assets/image/m-image-explain-4.png" />
            </div>
            
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.content-wrapper {
    background-color: #f9f9f9;
    .wrap{
        width: 1000px;
        margin: 0 auto;
        padding: 120px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
        }
        .explain-box {
            width: 100%;
            margin-top: 80px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 100%;
                &.pc {
                    display: block;
                }
                &.mo {
                    display: none;
                }
            }
            p {
                word-break: keep-all;
                margin-top: 50px;
                font-size: 20px;
                line-height: 130%;
                color: #666;
                width: 804px;
                text-align: center;
                span {
                    vertical-align:super;
                    color: #ff5746;
                    font-size: 12px;
                }
            }
            .bar {
                margin-top: 120px;
                width: 600px;
                background-color: #ffb000;
                height: 3px;
            }
            h5 {
                margin-top: 50px;
                font-size: 24px;
                color: #666;
            }
            .example-box {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                padding: 30px 40px;
                width: 700px;
                border-top: 0.5px solid #999;
                border-bottom: 0.5px solid #999;
                .l {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 24px;
                    line-height: 130%;
                    img {
                        height: 20px;
                        width: unset;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
.content-wrapper {
    .wrap{
        width: 100%;
        padding: 60px 20px 30px 20px;
        h1 {
            font-size: 28px;
        }
        .explain-box {
            margin-top: 40px;
            margin-bottom: 20px;
            img {
                &.pc {
                    display: none;
                }
                &.mo {
                    display: block;
                }
            }
            p {
                margin-top: 25px;
                font-size: 12px;
                line-height: 130%;
                color: #666;
                width: calc(100% - 40px);
                text-align: center;
                span {
                    font-size: 0.25rem;
                }
            }
            .bar {
                margin-top: 60px;
                width: 200px;
                height: 1px;
            }
            h5 {
                margin-top: 25px;
                font-size: 12px;
            }
            .example-box {
                margin-top: 20px;
                gap: 10px;
                padding: 15px 20px;
                min-width: 250px;
                width: calc(100% - 20px);
                max-width: 300px;
                .l {
                    width: 100%;
                    font-size: 12px;
                    img {
                        height: 10px;
                    }
                }
            }
        }
    }
}
}
</style>