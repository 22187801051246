import Vuex from "vuex";
import { v4 } from 'uuid'
import Vue from "vue";

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        session: {
            uuid: '',
            remote_addr: '',
            http_user_agent: '',
            http_referer: '',
            time_init_server: '',
            time_init_client: '',
            timestamp_init_server: '',
            timestamp_init_client: '',
            timestamp_last_elapsed_ms: '',
            exit_href: ''
        }
    },
    mutations: {
        initProfile (state, payload) {
        const initTimeSnapshot = new Date()
          state.session.uuid = v4()
          state.session.remote_addr = payload.remote_addr
          state.session.http_user_agent = payload.http_user_agent
          state.session.http_referer = payload.http_referer
          state.session.time_init_server = payload.time_local
          state.session.time_init_client = initTimeSnapshot.toISOString()
          state.session.timestamp_init_server = new Date(payload.time_local).getTime()
          state.session.timestamp_init_client = initTimeSnapshot.getTime()
          state.session.timestamp_last_elapsed_ms = initTimeSnapshot.getTime()
        },
        updateLastElapsedMs (state, timestamp_last_elapsed_ms) {
            state.session.timestamp_last_elapsed_ms = timestamp_last_elapsed_ms
        }
    },

    getters: {
        getSession (state) {
            return state.session
        }
    }
});