<template>
	<div class="card-item" @click="$emit('click', item.id)">
		<div class="img-wrap">
			<img v-if="item.thumbnail" :src="item.thumbnail" />
		</div>
		<div class="contents">
			<div v-show="isLoading">
				<skeleton width="30%" size="sm" />
				<skeleton width="85%" />
				<skeleton width="100%" />
				<skeleton width="75%" />
			</div>
			<div v-show="!isLoading">
				<div class="category">
					<span>{{ item.category ? tt(item.category.name) : '' }}</span>
					<p>{{ toStringByFormatting(item.create_datetime) }}</p>
				</div>
				<b>{{ item.title }}</b>
				<p v-html="item.content"></p>
			</div>
		</div>
	</div>
</template>

<script>
import Skeleton from '@/ui/page/Skeleton'

export default {
	name: 'CardItem',
	props: {
		item: {
			type: Object,
			default: () => ({}),
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	watch: {},
	components: {
		Skeleton,
	},
	methods: {
		toStringByFormatting(date, delimiter = '.') {
			if (date) {
				const dateTime = new Date(date)
				const year = dateTime.getFullYear()
				let month = dateTime.getMonth() + 1
				if (month < 10) {
					month = `0${month}`
				}
				let day = dateTime.getDate()
				if (day < 10) {
					day = `0${day}`
				}
				return [year, month, day].join(delimiter)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.card-item {
	width: 406px;
	background: #fff;
	border: 1px solid #eee;
	border-radius: 20px;
	cursor: pointer;
	.img-wrap {
		height: 200px;
		background: #fef9ec;
		background-image: url('~@/assets/svg/blog/empty-image.svg');
		background-repeat: no-repeat;
		background-position: center;
		border-radius: 20px 20px 0px 0px;
		overflow: hidden;
		img {
			width: 100%;
			margin-top: -1px;
		}
	}
	.contents {
		display: flex;
		flex-direction: column;
		gap: 15px;
		height: 182px;
		padding: 24px 33px;
		.category {
			display: flex;
			align-items: center;
			gap: 10px;
			font-size: 0.75rem;
			span {
				color: #faa400;
				white-space: nowrap;
			}
			p {
				color: #666;
			}
		}
		b {
			font-weight: 700;
			font-size: 1.125rem;
			overflow: hidden;
			word-break: break-word;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			min-height: 60px;
		}
		p {
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			word-break: break-word;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			font-size: 0.75rem;
			min-height: 0.75rem;
		}
	}
}
@media (max-width: 767px) {
	.card-item {
		width: 100%;
		.img-wrap {
			min-height: 164px;
			height: auto;
			img {
				width: 100%;
			}
		}
		.contents {
			gap: 10px;
			height: 146px;
			padding: 20px 27px;
			div {
				.category {
					font-size: 0.875rem;
				}
				> b {
					font-weight: 700;
					font-size: 1rem;
					min-height: 2rem;
				}
				> p {
					width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					word-break: break-word;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-size: 0.875rem;
				}
			}
		}
	}
}
</style>
