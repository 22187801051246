<template>
    <div class="document-item" @click="$emit('click', item.url)">
        <div class="contents">
            <div class="category">
                <span>{{ item.content }}</span>
                <p>{{ item.date }}</p>
            </div>
            <p>{{ item.title }}</p>
        </div>
        <div class="right">
            <img src="@/assets/svg/blog/arrow-right.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'DocumentItem',
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    methods: {
        toStringByFormatting(date, delimiter = '.') {
            const dateTime = new Date(date)
            const year = dateTime.getFullYear()
            let month = dateTime.getMonth() + 1
            if (month < 10) {
                month = `0${month}`
            }
            let day = dateTime.getDate()
            if (day < 10) {
                day = `0${day}`
            }
            return [year, month, day].join(delimiter)
        },
    },
}
</script>

<style lang="scss" scoped>
.document-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
    .contents {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 80%;
        .category {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 1rem;
            span {
                color: #faa400;
            }
            p {
                color: #666;
            }
        }
        > p {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-size: 1.125rem;
            font-weight: 500;
        }
    }
}
@media (max-width: 767px) {
    .document-item {
        border-bottom: 1px solid #eee;
        .contents {
            max-width: 100%;
            > p {
                white-space: normal;
                word-break: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
        .right {
            display: none;
        }
    }
}
</style>
