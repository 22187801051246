export default {
	data() {
		return {}
	},
	methods: {
		bootChannelTalk() {
			var w = window
			w.ChannelIO('boot', { pluginKey: 'd7020dc0-62b8-421a-9982-44e791be9f41' })
		},
		initChannelTalk() {
			this.bootChannelTalk();
		}
	},
}
