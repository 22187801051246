<template>
    <div class="content-wrapper">
        <div class="wrap">
            <h1>
                중소기업만의 특권!<br />비대면 서비스 바우처를 알고 계시나요?
            </h1>
            <img class="triangle pc" src="@/assets/image/image-voucher-triangle.png" />
            <img class="triangle mo" src="@/assets/image/m-image-voucher-triangle.png" />
            <p>
                중소기업의 디지털 전환(Digital Transformation)과 비대면 업무환경 구축을 위해
                비대면 서비스 도입비용을 중소벤처기업부에서 70%까지 지원하는 사업을 말합니다.
                공급기업은 중소벤처기업부 창업진흥원의 서류 및 면접 검증을 거쳐 선정됩니다.
            </p>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.content-wrapper {
    background-color: #f9f9f9;
    .wrap{
        width: 1000px;
        margin: 0 auto;
        padding: 120px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
        }
        .triangle {
            width: 550px;
            margin-top: 80px;
            &.pc {
                display: block;
            }
            &.mo {
                display: none;
            }
        }
        p {
            word-break: keep-all;
            width: 80%;
            margin-top: 60px;
            font-weight: 400;
            font-size: 24px;
            line-height: 129.99%;
            text-align: center;
        }
    }
}
@media (max-width: 1024px) {
.content-wrapper {
    .wrap{
        width: 100%;
        padding: 60px 0px 30px 0px;
        h1 {
            word-break: keep-all;
            font-size: 28px;
            width: 70%;
        }
        .triangle {
            width: calc(100% - 36px);
            margin-top: 40px;
            &.pc {
                display: none;
            }
            &.mo {
                display: block;
            }
        }
        p {
            word-break: keep-all;
            width: 75%;
            margin-top: 30px;
            font-weight: 400;
            font-size: 14px;
            line-height: 129.99%;
            text-align: center;
        }
    }
}
}
</style>