<template>
	<div id="Price">
		<intro-layout contentClass="center">
			<template #intro-content>
				<h1>
					<template v-if="$root.isPc">
						{{ tt(`유료 요금제 1계정만 결제해도`) }}<br/>
					</template>
					<template v-else>
						{{ tt(`유료 요금제`) }}<br/>{{ tt('1계정만 결제해도') }}<br/>
					</template>
					
					<img src="@/assets/image/icon-merck.svg" />
					{{ tt('10% 할인 혜택이 제공됩니다.') }}
				</h1>
				<p v-html="tt('연구실의 새로운 일상을 만드는<br>랩매니저 PRO의 요금제를 살펴보세요.')"></p>
			</template>
		</intro-layout>
		<PriceBox />
		<div class="promotion">
			<img class="pc" src="@/assets/image/image-m10-promotion.png" />
			<img class="mo" src="@/assets/image/m-image-m10-promotion.png" />
		</div>
		<div class="promotion purple">
			<img class="pc" src="@/assets/image/image-m10-sale.png" />
			<img class="mo" src="@/assets/image/m-image-m10-sale.png" />
		</div>
		<FunctionBox />
		<FaqBox />
		<FreeButtonBox />
		<RequestBox />
	</div>
</template>

<script>
import IntroLayout from '@/ui/page/IntroLayout'
import PriceBox from '../components/price/PriceBox.vue';
import FunctionBox from '../components/price/FunctionBox.vue'
import FaqBox from '../components/price/FaqBox.vue'
import FreeButtonBox from '../components/price/FreeButtonBox.vue';
import RequestBox from '../components/price/RequestBox.vue';
export default {
	name: 'Price',
	components: {
		IntroLayout,
		PriceBox,
		FunctionBox,
		FaqBox,
		FreeButtonBox,
		RequestBox,
	},
	metaInfo: {
		title: '요금안내_랩매니저 PRO',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인' },
			{vmid:'description', name:'description', content: '랩매니저 PRO 요금제, 대학부터 기업까지, 우리 연구실에 맞는 요금제를 만나보세요. 대학 연구실은 계정 수 무제한 요금제로 비용 부담없이 사용해보세요. 지금 유료 요금제 결제하면 머크 전상품 10% 할인!' },
			{vmid:'og:description', property:'description', content: '랩매니저 PRO 요금제, 대학부터 기업까지, 우리 연구실에 맞는 요금제를 만나보세요. 대학 연구실은 계정 수 무제한 요금제로 비용 부담없이 사용해보세요. 지금 유료 요금제 결제하면 머크 전상품 10% 할인!' },
		]
	},
	mounted() {},
	computed: {
	},
	methods: {},
}
</script>

<style lang="scss">
@import '@/assets/css/_price.scss';
</style>
