<template>
	<header id="Header" :class="{ transparent: scrollY < 10 }">
		<div class="wrap flex">
			<div class="logo" @click="goMain">
				<img src="@/assets/svg/logo.svg" />
			</div>
			<button class="m-menu" @click="showMenu = !showMenu" :class="{ close: showMenu }"></button>
			<nav class="nav" :class="{ active: showMenu }">
				<ul class="menu">
					<li v-for="(menu, index) in menuList" :key="index">
						<router-link :to="menu.path" :class="{ active: $route.path.indexOf(menu.path) >= 0 }">{{ tt(menu.name) }}</router-link>
					</li>
				</ul>
				<button class="btn-border" @click="goPro">{{ tt('로그인') }}</button>
				<button class="btn" @click="$root.openApp">{{ tt('무료사용') }}</button>
				<button class="btn" @click="goRequest">{{ tt('문의하기') }}</button>
				<!-- <b-form-radio-group :options="languages" v-model="selectLanguage" buttons /> -->
			</nav>
		</div>
	</header>
</template>

<script>
export default {
	mounted() {
		window.addEventListener('scroll', () => {
			this.scrollY = window.scrollY
		})
		this.selectLanguage = this.$root.language
	},
	data() {
		return {
			menuList: [
				{
					name: '주요기능',
					eName: 'Service',
					path: '/service',
				},
				{
					name: '요금안내',
					eName: 'Price',
					path: '/price',
				},
				{
					name: '비대면바우처',
					eName: 'Voucher',
					path: '/voucher',
				},
				{
					name: '전수조사',
					eName: 'Survey',
					path: '/survey',
				},
				{
					name: '블로그',
					eName: 'Blog',
					path: '/blog/all',
				},
			],
			showMenu: false,
			scrollY: 0,
			languages: [
				{ text: 'KOR', value: 'ko' },
				{ text: 'ENG', value: 'en' },
			],
			selectLanguage: 'ko',
		}
	},
	watch: {
		$route() {
			this.showMenu = false
		},
		'$route.query.language': {
			deep: true,
			handler() {
				this.$router.go()
			},
		},
		selectLanguage(val) {
			this.$router.replace({ query: { ...this.$route.query, language: val } })
		},
	},
	methods: {
		goAround() {
			//marketing: 무료로 시작하기 -> pro 로그인 되어있지 않았을 경우, 회원가입 유도 문구 노출 2022.11.21
			this.$router.push({
				name: 'Around',
				params: {
					key: 'pro_gateway',
					value: 'landing',
				},
			})
			window.ga('send', 'event', 'demo_start', 'demo_start')
			this.$root.openApp()
		},
		goPro() {
			//marketing: 단순 로그인의 경우, 문구 노출이 필요치 않아서 쿠키에서 제거
			this.$router.push({
				name: 'Around',
				params: {
					key: 'pro_gateway',
					value: 'remove',
				},
			})
			window.ga('send', 'event', 'login', 'login')
			this.$root.openLoginApp()
		},
		goMain() {
			if (this.$route.path == '/') {
				this.$router.go()
			} else {
				this.$router.push({
					name: 'Home',
				})
			}
		},
		goRequest() {
			this.$router.push({
				name: 'Request',
			})
		},
	},
}
</script>

<style lang="scss">
#Header {
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 10;
	transition: all 0.5s;
	&.transparent {
		background-color: transparent;
	}
	.wrap {
		height: 100px;
		.logo {
			height: 20px;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			img {
				height: inherit;
			}
			a {
				display: block;
				height: inherit;
			}
		}
		.nav {
			margin-left: auto;
			.menu {
				margin-right: 3.5rem;
				letter-spacing: 0.2px;
			}
			> button {
				height: 40px;
				&.btn-border {
					width: 88px;
				}
				&.btn {
					margin-left: 20px;
					width: 160px;
				}
			}
			.btn-group {
				margin-left: 3.5rem;
				label {
					border-radius: 0 0;
					background-color: transparent;
					border-color: transparent;
					color: black;
					font-weight: 400;
					font-size: 1rem;
					position: relative;
					&.active {
						font-weight: 700;
					}
					&:first-child {
						&:after {
							width: 1px;
							height: 14px;
							background-color: black;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							content: '';
						}
					}
				}
			}
		}
		.menu {
			display: inline-flex;
			margin-left: 40px;
			height: 36px;
			align-items: center;
			li {
				margin-left: 40px;
				a {
					display: block;
					&.active {
						font-weight: bold;
					}
				}
			}
		}
		.m-menu {
			display: none;
		}
	}
}

@media (max-width: 1024px) {
	#Header {
		width: 100%;
		background-color: #fff;
		z-index: 101;
		.wrap {
			width: 100%;
			height: 56px;
			padding: 0 0;
			.logo {
				margin-left: 20px;
				height: 16px;
				img {
					margin-top: -4px;
				}
			}
			.m-menu {
				margin-left: auto;
				display: block;
				width: 50px;
				height: 50px;
				background-image: url(~@/assets/svg/m-menu.svg);
				background-position: center;
				&.close {
					background-image: url(~@/assets/image/mob-icon-close@3x.png);
					background-size: 20px;
					background-repeat: no-repeat;
				}
			}
			.nav {
				position: fixed;
				width: 100vw;
				height: calc(100vh - 56px);
				display: block;
				margin-left: 0;
				background-color: #fff;
				border-left: 0;
				top: -100vh;
				left: 0;
				z-index: 100;
				text-align: left;
				border-top: 1px solid #e4e5ea;
				transition: all ease-in-out 0.3s;
				opacity: 0;
				&.active {
					top: 56px;
					opacity: 1;
				}
				> button {
					display: none;
				}
				.menu {
					display: block;
					margin: 0 0;
					height: unset;
					li {
						border-bottom: 1px solid #e4e5ea;
						margin-left: 0;
						font-weight: 600;
						width: 100%;
						a {
							display: block;
							width: 100%;
							padding: 15px 20px;
						}
					}
				}
				.btn-group {
					margin-left: 10px;
					margin-top: 10px;
				}
			}
		}
	}
}
</style>
