<template>
    <div class="carousel-wrapper" :class="{yellow: isYellow}">
        <p>{{ title }}</p>
        <b-carousel

            id="carousel-1"
            v-model="slide"
            :controls="list.length > 1 && !noControl"
            :indicators="!noIndicator"
            :interval="0"
        >
        <b-carousel-slide  v-for="image in list" :key="image" :img-src="image"  ></b-carousel-slide>
        </b-carousel>
    </div>
</template>
<script>
export default {
    props: {
		noControl: {
			type: Boolean,
			default: false,
		},
		noIndicator : {
			type: Boolean,
			default: false,
		},
        list: Array,
        title: String,
        isYellow: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            slide: 0,
        }
    }
}
</script>
<style lang="scss" scoped>
.carousel-wrapper {
    width: 100%;
    padding-bottom: 70px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &.yellow {
        background-color: #FDB929;
    }
}
p {
    font-family: 'SUITE', sans-serif;
    margin-top: 50px;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
}
.slide-wrapper {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-auto-flow: column;
    gap: 25px;
    padding: 30px 45px;
    overflow-y: auto;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    & > * {
        scroll-snap-align: center;
        width: 300px;
    }
}
.pointer-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .point {
        border-radius: 5px;
        width: 10px;
        height: 10px;
        background-color: #eaeaea;
        &.active {
            background-color: #000;
        }
    }
}
::v-deep {
    .carousel-item {
        padding: 0px 60px !important;
    }
    .carousel-control-prev, .carousel-control-next{
        opacity: 1;
        width: 60px;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
        background-image: url('~@/assets/image/icon-arrow-yellow.svg');
        width: 20px;
        height: 30px;

    }
    .carousel-control-prev-icon {
        transform: rotate(180deg);
    }
    .carousel-indicators {
        bottom: -45px;
        gap: 10px;
        li {
            margin: 0px; 
            opacity: 1;
            width: 10px;
            height: 10px;
            background-color: #eaeaea;
            border-radius: 50%;
            &.active {
                background-color: #000;
            }
        }
    }
}

.yellow {
    ::v-deep {
        .carousel-control-prev-icon {
            background-image: url('~@/assets/image/icon-arrow-white.svg');
        }    
        .carousel-control-next-icon {
            background-image: url('~@/assets/image/icon-arrow-white.svg');
        }    
    }
}
</style>