<template>
    <div class="card-list">
        <base-list
            :title="title"
            :subTitle="subTitle"
            :showAll="activeValue == 'all'"
            :totalCount="count"
            :pageCount="length"
            @expand="$emit('expand')"
            @page="$emit('page', $event)"
        >
            <div class="list">
                <card-item v-for="(item, index) in list" :key="index" :item="item" :isLoading="isLoading" @click="$emit('click', $event)" />
                <div class="no-data" v-if="!count && !isLoading">
                    <p v-html="tt('게시글이 존재하지 않습니다.<br>빠른 시일 내로 업데이트하도록 하겠습니다.')"></p>
                </div>
            </div>
        </base-list>
    </div>
</template>

<script>
import BaseList from '@/ui/page/BaseList.vue'
import CardItem from '@/ui/page/CardItem.vue'

export default {
    name: 'CardList',
    components: {
        BaseList,
        CardItem,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
        showAll: {
            type: Boolean,
            default: true,
        },
        list: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: 0,
        },
        length: {
            type: Number,
            default: 9,
        },
        activeValue: {
            type: String,
            default: '',
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
.card-list {
    .list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
    }
    .no-data {
        width: 100%;
        p {
            padding: 50px 0;
            text-align: center;
            width: 100%;
            font-size: 1.25rem;
            font-weight: 400;
        }
    }
}

@media (max-width: 767px) {
    .card-list {
        width: 100%;
        .no-data {
            width: 100%;
            p {
                padding: 30px 0;
                font-size: 1rem;
            }
        }
    }
}
</style>
