<template>
	<div class="Start"></div>
</template>

<script>
//네이버 전환 스트립트 인식을 위해 빈 페이지 설정

export default {
	async created() {
		let key = this.$route.params.key
		let value = this.$route.params.value
		let url = location.hostname.includes('lab-manager.com') ? '.lab-manager.com' : location.hostname
		if (value != 'remove') {
			await this.$cookies.set(key, value, null, null, url)
		} else {
			await this.$cookies.remove(key)
		}
		await window.marketingEvent('conversion')
		this.$nextTick(() => {
			this.$router.go(-1)
		})
	},
}
</script>
