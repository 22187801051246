<template>
	<div id="Investigation">
		<request-form title="화학물질 전수조사 예상 견적 알아보기" />
	</div>
</template>

<script>
import RequestForm from '@/components/form/RequestForm'

export default {
	components: {
		RequestForm,
	},
	data() {
		return {}
	},
	mounted() {
		window.scrollTo(0, 0)
	},
	methods: {},
}
</script>

<style lang="scss"></style>
