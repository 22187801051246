<template>
	<footer id="Footer">
		<div class="wrap">
			<div class="social">
				<a @click="downloadApp" target="_blank"><img src="@/assets/image/bt-appstore-small@3x.png"/></a>
				<a @click="downloadApp" target="_blank"><img src="@/assets/image/bt-playstore-small@3x.png"/></a>
				<!-- <a href="https://lab-manager.tistory.com/"
					><img src="@/assets/image/bt-tstory-small@3x.png"
				/></a> -->
			</div>
			<div class="provision">
				<a href="https://www.notion.so/d41a74be81124ea3a9fb5f91409d490f" target="_blank">{{ tt('개인정보처리방침') }}</a>
				<a href="https://smartjack.notion.site/15d6730042364ea1a511ab382192d3f7" target="_blank">{{ tt('서비스이용약관') }}</a>
				<router-link to="/request">{{ tt('문의하기') }}</router-link>
				<a href="https://pro.lab-manager.com" target="_blank" class="m-none">{{ tt('PC버전 접속하기') }}</a>
				<a href="https://bit.ly/31Toxxj">{{ tt('랩매니저 스토어') }}</a>
			</div>
			<div class="copyright">
				<h5 class="m-none">
					ⓒ 2022 Smartjack Co., Ltd. All rights reserved.
				</h5>
				<p>
					<span>{{ tt('주식회사 스마트 잭') }}</span>
					<span class="line m-none">|</span>
					<span>{{ tt('대표자: 김건우') }}</span>
					<span class="line m-none">|</span>
					<span>{{ tt('사업자등록번호: 630-86-00974') }}</span>
					<span class="line m-none">|</span>
					<span>{{ tt('통신판매업신고: 제 2018-서울성동-0924 호') }}</span>
				</p>
				<p>
					<span>Tel: 1533-3061</span>
					<span>{{ tt('주소: 서울시 성동구 성수일로4가길 10, 3층') }}</span>
					<span>{{ tt('이메일: lmcs@smartjackwp.com') }}</span>
				</p>
				<p class="w-none">
					ⓒ 2022 Smartjack Co., Ltd. All rights reserved.
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	methods: {
		downloadApp() {
			this.$router.push({
				name: 'Around',
			})
			window.open('https://lmpro.page.link/app')
		},
	},
}
</script>

<style lang="scss">
#Footer {
	width: 100%;
	background: #000;
	padding: 110px 0;
	color: #f9f9f9;
	.wrap {
		text-align: center;
		.social {
			display: flex;
			align-items: center;
			height: auto;
			justify-content: center;
			a {
				display: inline-block;
				height: 54px;
				img {
					height: 46px;
					margin: 0 10px;
				}
			}
		}
		.provision {
			padding: 60px 0;
			font-size: 15px;
			a {
				font-weight: 400;
				padding: 0 15px;
			}
		}
		.copyright {
			h5 {
				font-size: 21px;
				margin-bottom: 14px;
			}
			p {
				font-size: 15px;
				font-weight: 400;
				span {
					margin: 0 5px;
					display: inline-block;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#Footer {
		background-color: rgba(0, 0, 0, 0.87);
		padding: 30px;
		padding-bottom: 80px;
		.wrap {
			text-align: center;
			.social {
				display: flex;
				align-items: center;
				height: auto;
				a {
					display: inline-block;
					height: 36px;
					img {
						height: 36px;
						margin: 0 3px;
					}
				}
				img {
					height: 36px;
					margin: 0 3px;
				}
			}
			.provision {
				padding: 15px 0;
				font-size: 0.95rem;
				text-align: left;
				a {
					font-family: AppleSDGothic;
					padding: 0;
					margin-bottom: 5px;
					display: block;
					&.m-none {
						display: none;
					}
				}
			}
			.copyright {
				text-align: left;
				p {
					font-size: 0.8rem;
					font-family: AppleSDGothicNeoUL;
					span {
						display: block;
						&.line {
							display: none;
						}
					}
					&.w-none {
						margin-top: 20px;
					}
				}
			}
		}
	}
}
</style>
