import commonLng from '@/locales/commonLng.json'

export const localization = {
	install(Vue) {
		Vue.prototype.tt = function(key, ...args) {
			let result = key
			if (this.$root.language == 'en') {
				if (commonLng[key]) {
					result = commonLng[key]
				}
			}

			for (let i = 0; i < args.length; i++) {
				result = result.replace(new RegExp('\\$\\{' + (i + 1) + '\\}', 'g'), args[i])
			}
			return result
		}
	},
}

export default localization
