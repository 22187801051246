<template>
	<div id="GatheringPictureForm">
		<div class="user-info">
			<div class="content">
				<img src="@/assets/image/gathering/gathering-picture-type1-intro.png" />

				<button v-b-modal.gathering-form @click="goRequest">
					<img src="@/assets/svg/gathering/gathering-picture-intro-btn.svg" />
				</button>
			</div>
		</div>
		<div class="partner content">
			<p><b>대학 연구실부터 정출연, 기업 연구실까지</b><br />국내 최고 4,623개의 연구실이 선택한 이유가 있습니다.</p>
			<img src="@/assets/image/gathering/partners.png" />
		</div>
		<div class="promotion content">
			<img src="@/assets/image/gathering/gathering-type1-picture-image.png" />
		</div>
		<div class="reason content">
			<h6 style="font-size: 1.5rem; font-weight: 600; color: #fec741; margin: 40px 0;">랩매니저 PRO를<br />시작해야 하는 이유</h6>
			<mobile-carousel :autoplay="false" :list="optionList.list1" contentClass="opinion" />
		</div>
		<div class="marketing content">
			<img class="review" src="@/assets/svg/gathering/gathering-picture-review1.svg" />
			<div class="opinions">
				<img src="@/assets/image/gathering/gathering-picture-opinion1.png" />
				<mobile-carousel :autoplay="false" :list="optionList.list2" contentClass="opinion" imgType="svg" />
				<img src="@/assets/image/gathering/gathering-picture-opinion2.png" />
				<mobile-carousel :autoplay="false" :list="optionList.list3" contentClass="opinion" imgType="svg" />
			</div>
		</div>
		<div class="footer">
			<div class="content">
				<p style="font-size: 1.5rem; font-weight:400; margin: 20px 0; color: white;">
					당신의 연구실도<br />이제, <b style="font-weight: 600; display: inline-block;">랩매니저 PRO</b>로 관리해보세요.
				</p>
				<button v-b-modal.gathering-form>
					<img src="@/assets/svg/gathering/gathering-picture-intro-btn.svg" />
				</button>
			</div>
		</div>

		<modal-gathering-form />
	</div>
</template>

<script>
import ModalGatheringForm from '@/components/modals/ModalGatheringForm'
import MobileCarousel from '@/ui/page/MobileCarousel'

export default {
	name: 'GatheringForm',
	components: {
		ModalGatheringForm,
		MobileCarousel,
	},
	created() {
		this.setImage()
	},
	data() {
		return {
			optionList: {},
			opinions: [1, 2, 3, 4],
			isAgree: false,
		}
	},
	methods: {
		goRequest() {
			window.ga('send', 'event', 'now-request', 'now-request')
			window.marketingEvent('conversion')
		},
		setImage() {
			this.optionList = {
				list1: [],
				list2: [],
				list3: [],
			}
			for (let i = 1; i <= 4; i++) {
				this.optionList.list1.push({ image: `gathering/reason-${i}.png` })
				this.optionList.list2.push({ image: `gathering/gathering-picture-opinion1-${i}.svg` })
				this.optionList.list3.push({ image: `gathering/gathering-picture-opinion2-${i}.svg` })
			}
		},
	},
}
</script>

<style lang="scss">
#GatheringPictureForm {
	.content {
		width: 640px;
		margin: 0 auto;
		text-align: center;
		img {
			width: 100%;
		}
	}
	.user-info,
	.footer {
		width: 100%;
		background: linear-gradient(315deg, #fec741 0%, #fed671 100%);
		padding: 100px 0 60px;
		button {
			margin-top: 30px;
		}
	}
	.partner {
		padding: 40px 0;
		p {
			font-size: 1rem;
			font-weight: 400;
			margin: 0;
		}
		img:last-child {
			margin-top: 40px;
		}
	}
	.marketing {
		padding-top: 120px;
		.review {
		}
		.opinions {
			> img {
				width: 388px;
				margin: 100px 0 40px;
			}
		}
		.VueCarousel-slide {
			img {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
	.footer {
		padding: 60px 0 40px;
		min-height: unset;
		margin-top: 80px;
	}
}

@media (max-width: 767px) {
	#GatheringPictureForm {
		.content {
			width: 90%;
			img {
				width: 95%;
			}
		}
		.user-info {
			padding: 60px 0 60px;
		}
		.marketing {
			padding-top: 40px;
			.review {
				width: 90%;
			}
			.opinions {
				> img {
					width: calc(110%);
					margin: 60px -5% 40px;
				}
			}
			.VueCarousel-slide {
				img {
					width: 90%;
					margin: 0 auto;
				}
			}
		}
	}
}
</style>
