<template>
    <div class="content-wrapper">
        <div class="wrap">
            <h1>
                비대면 바우처 서비스,<br/>우리 회사도 신청할 수 있을까요?
            </h1>
            <div class="apply-step">
                <div class="step-box">
                    <h2>01</h2>
                    <h3>중소기업이어야 해요.</h3>
                    <p>(중소기업 기본법 제2조에 따른 중소기업)</p>
                </div>
                <div class="bar" />
                <div class="step-box">
                    <h2>02</h2>
                    <h3>20년, 21년, 22년 3년 간 비대면 서비스 바우처 사업에 선정되어 바우처를 일부라도 지원받은 기업은 신청할 수 없어요.</h3>
                </div>
                <div class="bar" />
                <div class="step-box">
                    <h2>03</h2>
                    <h3>단, 장애인･여성기업으로 인증받은 기업에 한해 최대 2회까지 신청(지원)이 가능해요.</h3>
                </div>
                <div class="bar" />
                <div class="step-box">
                    <h2>04</h2>
                    <h3>대표자가 같은 다수 기업(개인, 법인)은 1개 기업만 신청이 가능해요.</h3>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.content-wrapper {
    background-color: white;
    .wrap{
        width: 1000px;
        margin: 0 auto;
        padding: 120px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
        }
        .apply-step {
            width: 100%;
            margin-top: 80px;   
            display: flex;
            flex-direction: column;
            align-items: center;
            .step-box {
                width: 100%;
                padding: 50px 100px;
                border: 1px solid #d3d3d3;
                text-align: center;
                border-radius: 20px;
                h2 {
                    font-size: 60px;
                    font-weight: 500;
                    color: #ffb000;
                }
                h3 {
                    word-break: keep-all;
                    margin-top: 10px;
                    font-size: 32px;
                }
                p {
                    margin-top: 5px;
                    font-size: 20px;
                }
            }
            .bar {
                height: 90px;
                width: 1px;
                background-color: #d3d3d3;
            }
        }
    }
}
@media (max-width: 1024px) {
.content-wrapper {
    .wrap{
        width: 100%;
        padding: 60px 20px 30px 20px;
        h1 {
            width: 70%;
            word-break: keep-all;
            font-size: 28px;
        }
        .apply-step {
            margin-top: 40px;   
            .step-box {
                width: 100%;
                padding: 30px 50px;
                h2 {
                    font-size: 36px;
                }
                h3 {
                    margin-top: 5px;
                    font-size: 20px;
                }
                p {
                    margin-top: 2px;
                    font-size: 12px;
                }
            }
            .bar {
                height: 45px;
                width: 1px;
                background-color: #d3d3d3;
            }
        }
    }
}
}
</style>