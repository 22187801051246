<template>
	<b-modal id="modal-complete-request" ref="modal" size="md" centered hide-footer>
		<div class="content">
			<p v-html="tt('소개서 신청이 완료되었습니다.<br>빠른 시일 내 발송 드리겠습니다. 감사합니다!')"></p>
			<!-- <span>{{ tt('*4시 이후 신청건은 다음 날 발송됩니다.') }}</span> -->
			<button class="btn btn-yellow" @click="goAround">{{ tt('무료로 시작하기') }}</button>
		</div>
	</b-modal>
</template>

<script>
export default {
	methods: {
		goAround() {
			this.$router.push({
				name: 'Around',
			})
			window.ga('send', 'event', 'demo_start', 'demo_start')
			if (this.$root.isPc) {
				window.open('https://pro.lab-manager.com')
			} else {
				window.open('https://lmpro.page.link/app')
			}
		},
	},
}
</script>

<style lang="scss">
#modal-complete-request {
	.modal-header {
		border-bottom: 0;
	}
	.content {
		p {
			font-size: 1.5rem;
			line-height: 1.5;
		}
	}
	.btn-yellow {
		height: 80px;
		border-radius: 20px;
		margin-bottom: 40px;
		width: 250px;
		font-size: 1.5rem;
	}
}

.content {
	width: 100%;
	text-align: center;
	p {
		font-size: 1.5rem;
		font-weight: 400;
		margin-bottom: 40px;
	}
	span {
		display: block;
		font-size: 1.25rem;
		font-weight: 400;
		margin: 30px 0 40px;
	}
}
</style>
