<template>
	<div class="intro" ref="intro" :class="contentClass">
		<div class="wrap">
			<slot name="intro-content" />
			<button class="scroll-btn" @click="goScroll" v-if="isScroll">
				<img src="@/assets/svg/arrow-bottom.svg" />
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'IntroLayout',
	props: {
		isScroll: {
			type: Boolean,
			default: false,
		},
		contentClass: {
			type: String,
			default: '',
		},
	},
	methods: {
		goScroll() {
			window.scrollTo({ top: this.$refs['intro'].clientHeight, behavior: 'smooth' })
		},
	},
}
</script>

<style lang="scss" scoped>
.intro {
	position: relative;
	width: 100%;
	height: calc(100vh + 100px);
	background-color: #fffcf4;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-top: -100px;
	box-sizing: border-box;
	&.center {
		.wrap {
			text-align: center;
		}
	}
	.scroll-btn {
		width: 100%;
		max-width: 1280px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 150px;
	}
}

@media (min-width: 768px) and (max-width: 1280px) {
	.intro {
		.scroll-btn {
			bottom: 80px;
		}
	}
}

@media (max-width: 767px) {
	.intro {
		.scroll-btn {
			width: 90%;
			bottom: 30px;
		}
	}
}
</style>
