<template>
	<div class="mobileCarousel w-none" :class="contentClass">
		<carousel :perPage="1" :autoplay="autoplay" :autoplayTimeout="autoplayTimeout">
			<slide v-for="(item, index) in list" :key="`item_${index}`">
				<div class="boxwrap">
					<h6 v-if="item.name">{{ tt(item.name) }}</h6>
					<div class="imgbox">
						<img v-if="item.image" :src="require(`@/assets/${imgType}/${item.image}`)" />
						<img v-if="item.image2" :src="require(`@/assets/${imgType}/${item.image2}`)" />
					</div>
					<div class="txtbox" v-if="item.title">
						<b>{{ tt(item.title) }}</b>
						<p v-if="item.description" v-html="tt(item.description)"></p>
					</div>
				</div>
			</slide>
		</carousel>
	</div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'MobileCarousel',
	props: {
		autoplay: {
			type: Boolean,
			default: true,
		},
		autoplayTimeout: {
			type: Number,
			default: 5000,
		},
		list: {
			type: Array,
			default: () => [],
		},
		contentClass: {
			type: String,
			default: '',
		},
		imgType: {
			type: String,
			default: 'image',
		},
	},
	components: {
		Carousel,
		Slide,
	},
}
</script>

<style lang="scss" scoped>
.mobileCarousel {
	&.opinion {
		display: block;
		.boxwrap {
			border: 0;
			box-shadow: unset;
			padding-bottom: 0;
			border-radius: 0;
			img {
				width: 80%;
				margin: 0 auto;
			}
		}
	}
	.boxwrap {
		border: 1px solid #eee;
		box-sizing: border-box;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03);
		border-radius: 5px;
		padding-bottom: 20px;
		background-color: #fff;
		img {
			width: 100%;
		}
		.txtbox {
			width: 100%;
			padding: 20px 20px 0;
			text-align: left;
			h6 {
				font-size: 0.95rem;
				display: inline-block;
				font-weight: 600;
			}
			b {
				font-size: 0.95rem;
				display: inline-block;
				margin-left: 5px;
				font-weight: 600;
			}
			p {
				font-size: 0.95rem;
				padding-top: 8px;
				word-break: keep-all;
			}
		}
	}
	.VueCarousel-dot-container {
		background: #f2f2f2;
		height: 30px;
		padding: 0 5px;
		border-radius: 15px;
		.VueCarousel-dot {
			margin-top: 0 !important;
			background-color: rgb(200, 200, 200) !important;
		}
		.VueCarousel-dot--active {
			background-color: rgb(0, 0, 0) !important;
		}
	}
}
</style>
