<template>
	<section>
		<form class="formBox wrap" @submit.prevent="sendFeedback">
			<!-- <h4 v-if="title">{{ tt(title) }}</h4> -->
			<div class="badge-required">
				필수 입력사항
			</div>
			<div class="title">
				{{tt('담당자님의 정보를 알려주세요.')}}
			</div>
			<div class="inputBox">
				<h6>{{ tt('이름') }} </h6>
				<input type="text" :placeholder="tt('문의 주시는 분의 성함을 남겨주세요.')" v-model="form.manager" />
			</div>
			<div class="inputBox">
				<h6>{{ tt('연락처') }} </h6>
				<input type="text" :placeholder="tt('연락 가능한 전화번호를 입력해 주세요.')" v-model="form.phone" @input="setPhoneForm" />
			</div>
			<div class="inputBox">
				<h6>
					{{ tt('이메일') }}
					
				</h6>
				<input type="text" :placeholder="tt('정확한 이메일 주소를 입력해 주세요.')" v-model="form.email" />
			</div>
			<div class="inputBox">
				<h6>{{ tt('회사명 또는 단체명') }} </h6>
				<input type="text" :placeholder="tt('회사명이나 단체명을 입력해 주세요.')" v-model="form.belong" />
			</div>
			<div class="badge-option">
				선택 입력사항
			</div>
			<div class="title">
				{{tt('도입 및 궁금하신 점을 알려주세요.')}}
			</div>
			<div class="inputBox lg">
				<textarea :placeholder="tt('상세하게 문의를 등록해 주시면 보다 더 정확한 답변이 가능합니다.')" v-model="form.content"></textarea>
			</div>
			<div class="require-info">
				<!-- <h2>개인정보 수집 및 이용 동의 (필수)</h2>
				<p>
					주식회사 스마트 잭은 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.
				</p>
				<p>
					개인정보의 수집, 이용 목적<br />
					<span>· 서비스 및 제품 소개, 고객문의 상담, 필수자료 전달, 프로모션 및 할인 혜택 안내</span>
				</p>
				<p>
					수집하는 개인정보의 항목<br />
					<span>· 필수 항목: 이름, 연락처, 회사명, 이메일 </span>
				</p>
				<p>
					개인정보의 보유 및 이용기간<br />
					<span>· 3년 간 보관 후 삭제</span>
				</p> -->
				<p class="agree-box" rol="tab">
					<label for="agree" @click="isAgree = !isAgree">
						<img v-if="isAgree" src="@/assets/svg/ic-check-on.svg" />
						<img v-else src="@/assets/svg/ic-check-off.svg" /> 
						[필수] 개인정보 수집 및 이용 동의
					</label>
					<img class="arrow" @click="isAreeInfoFold = !isAreeInfoFold" :class="{fold:!isAreeInfoFold}" src="@/assets/svg/ic-arrow-right-yellow.svg" />
				</p> 
				<b-collapse v-model="isAreeInfoFold" class="mt-2" accordion="tabpanel" role="tabpanel">
					<div class="fold-content">
						<p>
							개인정보의 수집, 이용 목적<br />
							<span>• 서비스 및 제품 소개, 고객문의 상담, 필수자료 전달, 프로모션 및 할인 혜택 안내</span>
						</p>
						<p>
							수집하는 개인정보의 항목<br />
							<span>• 필수 항목: 이름, 연락처, 회사명, 이메일 </span>
						</p>
						<p>
							개인정보의 보유 및 이용기간<br />
							<span>• 문의일로부터 3개월 보관 후 즉시 파기</span>
						</p>
					</div>
				</b-collapse>
			</div>
			<button type="button" @click="sendFeedback">
				{{ tt(btnText) }}
			</button>
		</form>
	</section>
</template>

<script>
import { sendMessage } from '@/services/slack.js'
export default {
	name: 'RequestForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		requiredName: {
			type: Boolean,
			default: true,
		},
		requiredPhone: {
			type: Boolean,
			default: true,
		},
		requiredContent: {
			type: Boolean,
			default: true,
		},
		requiredBelong: {
			type: Boolean,
			default: false,
		},
		btnText: {
			type: String,
			default: '문의 등록하기',
		},
	},
	components: {},
	data() {
		return {
			form: {
				email: '',
				manager: '',
				phone: '',
				content: '',
				belong: '',
			},
			isAreeInfoFold: false,
			isAgree: false,
		}
	},
	methods: {
		async sendFeedback() {

			if(!this.form.manager) {
				return alert("이름을 입력해주세요.")
			}

			//휴대폰입력이 필요한 경우 + 휴대폰을 제대로 입력하지 않았을 경우
			if (this.form.phone.length >= 1) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.form.phone)) {
					return alert('연락처가 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
				}
			}

			let emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i

			if (!this.form.email) {
				return alert('이메일 주소를 입력해주세요.')
			} else if (!emailRule.test(this.form.email)) {
				return alert('이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
			}
			if(!this.form.belong) {
				return alert('회사명이나 단체명을 입력해주세요.')
			}
			if(!this.isAgree)
				return alert("개인정보 수집 및 이용에 동의해주세요.")

			try {
				await this.$service.client.submitRequest(this.form)
				await sendMessage(this.form, '문의 등록')
				// window.marketingEvent('conversion')

				alert('문의가 등록되었습니다. 감사합니다.')

				this.form = {
					email: '',
					manager: '',
					phone: '',
					content: '',
					belong: '',
				}
				this.isAgree = false
				this.$router.push({
					name : "Home"
				})
			} catch (e) {
				alert('오류로 인해 문의가 접수되지 않았습니다.\n다시 한 번 시도해주세요')
			}
		},
		autoHypen(str) {
			str = str.replace(/[^0-9]/g, '')
			let tmp = ''

			if (str.substring(0, 2) == '02') {
				// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
				if (str.length < 3) {
					return str
				} else if (str.length < 6) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2)
					return tmp
				} else if (str.length < 10) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 3)
					tmp += '-'
					tmp += str.substr(5)
					return tmp
				} else {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 4)
					tmp += '-'
					tmp += str.substr(6, 4)
					return tmp
				}
			} else {
				// 핸드폰 및 다른 지역 전화번호 일 경우
				if (str.length < 4) {
					return str
				} else if (str.length < 7) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3)
					return tmp
				} else if (str.length < 11) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 3)
					tmp += '-'
					tmp += str.substr(6)
					return tmp
				} else {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 4)
					tmp += '-'
					tmp += str.substr(7)
					return tmp
				}
			}
		},
		setPhoneForm(e) {
			const phoneNumber = this.autoHypen(e.target.value)
			this.form.phone = phoneNumber
		},
	},
}
</script>

<style lang="scss" scoped>
::v-deep {
	.collapse {
		margin-top: 0px !important;
	}
}
section{ 
	margin: 0 auto;
	width: 1000px;
	padding: 60px 0px;

	.required {
		color: #B40000 !important;
	}
}
.badge-option, .badge-required {
	font-size: 16px;
	font-weight: 700;
	height: 30px;
	width: fit-content;
	border-radius: 10px;
	background-color: #fec741;
	padding: 5px 15px;
	display: flex;
	&.badge-option {
		margin-top: 60px;
		background-color: #FFF3D6;
	}
}
.formBox {
	width: 100%;
	text-align: left;
	// padding-top: 110px;
	position: relative;
	display: inline-flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 30px;
	h4 {
		font-size: 36px;
		font-weight: 600;
	}
	.inputBox {
		width: calc(50% - 60px);
		padding-top: 20px;
		h6 {
			font-weight: 600;
			font-size: 18px;
			padding-bottom: 20px;
			span {
				font-size: 0.875rem;
				display: inline-block;
				color: #a0a0a0;
			}
		}
		input {
			width: 100%;
			border: 1px solid #c7c7c7;
			border-radius: 10px;
			height: 45px;
			padding-left: 10px;
			transition: 0.3s all;
			&:focus {
				border: 0.8px solid #444444;
				box-shadow: 2px 2px 4px 0px #00000026;
			}
		}
		select {
			width: 100%;
			height: 45px;
			border-radius: 10px;
			border: 1px solid #c7c7c7;
			padding-left : 10px;
			padding-right: 16px;
			-webkit-appearance:none; /* for chrome */
			-moz-appearance:none; /*for firefox*/
			appearance:none;
			background: url(~@/assets/image/icon-dropdown.svg) no-repeat 96% 50%/15px auto;
		}
		select.empty {
		  color: #777;
		}

		textarea {
			resize: none;
			width: 100%;
			border-radius: 10px;
			border: 1px solid #c7c7c7;
			height: 350px;
			padding: 20px 15px;
			&:focus {
				border: 0.8px solid #444444;
				box-shadow: 2px 2px 4px 0px #00000026;
			}
		}
		&.lg {
			width: 100%;
		}
	}
	button {
		height: 65px;
		font-size: 24px;
		background-color: #fec741;
		border-radius: 10px;
		color:black;
		font-weight: 700;
		margin: 0 auto;
		margin-top: 40px;
		margin-bottom: 134px;
		padding: 0 70px;
	}
	.imgbox {
		width: 200px;
		position: absolute;
		bottom: 0;
		right: 0;
		img {
			width: 100%;
		}
	}
	.title {
		margin-top: 30px;
		width: 100%;
		border-bottom: 1px solid #999;
		font-size: 30px;
		line-height: 130%;
		padding-bottom: 10px;
		font-weight: 700;
	}
	.require-info {
		width: 100%;
		border: 0.8px solid #D3D3D3;
		border-radius: 10px;
		padding: 30px 40px; 
		// padding-bottom: 10px;
		h2 {
			font-weight: 600;
			font-size: 20px;
			line-height: 130%;
			margin-bottom: 14px;
		}
		.agree-box {
			justify-content: space-between;
			display: flex;
			align-items: center;
			// padding-bottom: 20px;
			img {
				cursor: pointer;
				width: 26px;
			}
			.arrow {
				transition: 0.3s all;
				transform: rotate(90deg);
				&.fold {
					transform: rotate(0);
				}
			}
		}
		label {
			display: flex;
			align-items: center;
			gap: 16px;
			font-weight: 700;
			font-size: 20px;
			line-height: 130%;
			cursor: pointer;
			margin-bottom: 0;
		}
		.fold-content {
			// margin-top: 20px;
			// padding-top: 30px;
			padding-bottom: 20px;
			// border-top: 0.5px solid #ccc;
			display: flex;
			flex-direction: column;
			gap: 10px;
			&::before {
				content: '';
				display: block;
				border-top: 0.5px solid #ccc;
				width: 100%;
				height: 0px;
				margin-bottom: 25px;
				margin-top: 25px;
			}
			p {
				padding-left: 40px;
				font-size: 14px;
				font-weight: 300;
				line-height: 130%;
				span {
					padding-left: 5px;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1139px) {
section{ 
	width: 100%;

}
	.formBox {
		width: 100%;
		display: block;
		text-align: left;
		position: relative;
		padding: 110px 5% 0;
		h4 {
			font-size: 36px;
		}
		.inputBox {
			width: 100%;
			padding-top: 30px;
			h6 {
				font-size: 24px;
				padding-bottom: 10px;
			}
			input {
				width: 100%;
			}
			select {
				width: 100%;
			}
			textarea {
				width: 100%;
				border-radius: 10px;
				border: 1px solid #c7c7c7;
				height: 400px;
				padding: 20px 15px;
			}
		}
		button {
			width: 50%;
			height: 60px;
			font-size: 18px;
			background-color: #fec741;
			border-radius: 10px;
			font-family: AppleSDGothicNeoB;
			margin-top: 40px;
			margin-bottom: 134px;
		}
		.imgbox {
			width: 200px;
			position: absolute;
			bottom: 0;
			right: 0;
			img {
				width: 100%;
			}
		}
	}
}

@media (max-width: 767px) {
section{ 
	width: 100%;
	padding: 30px;
	& > p {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.025em;
	}

}
	.formBox {
		width: 100%;
		display: block;
		padding-top: 40px;
		.title {
			font-size: 24px;
			word-break: keep-all;
			margin-top: 30px;
			padding-right: 120px;
		}
		h4 {
			font-size: 1.7rem;
			padding-bottom: 20px;
		}
		.inputBox {
			width: 100%;
			padding-top: 30px;
			h6 {
				font-size: 0.95rem;
				padding-bottom: 5px;
				span {
					font-size: 0.75rem;
				}
			}
			input {
				width: 100%;
				height: 40px;
				font-size: 0.9rem;
			}
			select {
				width: 100%;
				height: 40px;
				font-size: 0.9rem;
			}
			textarea {
				height: 20vh;
				font-size: 0.9rem;
				padding: 10px;
			}
		}
		button {
			display: block;
			padding: 0px 50px;
			height: 40px;
			font-size: 0.95rem;
			border-radius: 10px;
			margin: 60px auto;
		}
		.require-info {
			margin-top: 20px;
			padding: 15px 10px;
			h2 {
				font-size: 12px;
			}
			p {
				font-size: 12px;
			}
			.agree-box {
				img {
					width: 20px;
				}
			}
			label {
				font-size: 14px;
				line-height: 130%;
				margin-left: 5px;
				cursor: pointer;
				margin-bottom: 0;
				}
			.fold-content {
				&::before {
					content: '';
					display: block;
					border-top: 0.5px solid #ccc;
					width: 100%;
					height: 0px;
					margin-bottom: 15px;
					margin-top: 15px;
				}
				p {
					padding-right: 30px;
					font-size: 12px;
				}
			}
		}
	}
	.wrap {
		padding: 0px;
	}
	.badge-option, .badge-required {
		font-size: 14px;
	}
}
</style>
