<template>
    <div class="content-wrapper">
        <div class="wrap">
            <h1>
                비대면 서비스 바우처<br/>이용방법을 알려드릴게요.
            </h1>
            <div class="step-wrapper">
                <div class="box">
                    <div class="label">
                        신청하고
                    </div>
                    <div class="step">
                        Step 01
                    </div>
                    <h2>
                        비대면 바우처 수요기업<br/>신청을 진행합니다.
                    </h2>
                    <div class="link">
                        <a target="_blank" href="https://k-voucher.kr/">수요기업 신청하기<img src="@/assets/image/icon-arrow-yellow.svg" /></a>
                        <a target="_blank" href="https://docs.google.com/document/d/1dPRCkN4-WEuwfv8Q61PyHA2rI_QlIgBjcYsVl-CT9Tk/edit?usp=sharing">수요기업 신청 가이드<img src="@/assets/image/icon-arrow-yellow.svg" /></a>
                    </div>
                </div>
                <img class="next" src="@/assets/image/image-arrow-down.svg" />
                <div class="box">
                    <div class="label">
                        체험하고
                    </div>
                    <div class="step">
                        Step 02
                    </div>
                    <h2>
                        회원가입 후<br/>무료사용을 진행합니다.
                    </h2>
                    <p>•&nbsp;최초 가입한 1인 이외에 추가 인원이 초대되는 경우<br/> 30일 기간한정 체험으로 변경됩니다.</p>
                    <div class="link">
                        <a href="#" @click="$root.openApp">무료사용 바로가기<img src="@/assets/image/icon-arrow-yellow.svg" /></a>
                    </div>
                </div>
                <img class="next" src="@/assets/image/image-arrow-down.svg" />
            </div>
            <div class="step-wrapper">
                <div class="box">
                    <div class="label">
                        구매하고
                    </div>
                    <div class="step">
                        Step 03
                    </div>
                    <h2>
                        비대면 바우처 플랫폼에서<br/>랩매니저 PRO 바우처를<br/>구매합니다.
                    </h2>
                    <div class="link">
                        <a target="_blank" href="https://k-voucher.kr/page/productSearch?mode=update&seq=2050">랩매니저 PRO 바우처 구매 바로가기<img src="@/assets/image/icon-arrow-yellow.svg" /></a>
                    </div>
                </div>
                <img class="next" src="@/assets/image/image-arrow-down.svg" />
                <div class="box">
                    <div class="label">
                        사용하고
                    </div>
                    <div class="step">
                        Step 04
                    </div>
                    <h2>
                        요금제 적용 요청 후<br />랩매니저 PRO를 사용합니다.
                    </h2>
                    <div class="promotion">
                        <p class="accent">특별혜택 안내</p>
                        <img src="@/assets/image/image-merck-10.png" />
                        <p>* 유료 라이선스 이용기간 동안 제공!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.content-wrapper {
    background-color: #FFF3D6;
    .wrap{
        width: 1000px;
        margin: 0 auto;
        padding: 120px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
            margin-bottom: 80px;
        }
        .step-wrapper {
            display: flex;
            gap: 60px;
            margin-bottom: 25px;
            .next {
                 display: none;
            }
            .box { 
                padding: 30px;
                width: 450px;
                height: 400px;
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(255, 232, 175, 0.5);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                .label {
                    width: fit-content;
                    padding: 3px 30px;
                    background: linear-gradient(180deg, #FEC741 0%, #FFA90B 100%);
                    border-radius: 5px;
                    font-size: 20px;
                    font-weight: 800;
                    color:white; 

                }
                .step {
                    margin-top: 30px;
                    color: #ff8248;
                    font-size: 20px;
                    font-weight: 700;
                    text-decoration: underline;
                }
                h2 {
                    font-size: 30px;
                    font-weight: 700;
                    margin-top: 20px;
                }
                & > p {
                    margin-top: 50px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 129.99%;
                    list-style-position: outside;
                    color: #666666;
                }
                .link {
                    margin-top: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    & > * {
                        display: block;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 129.99%;
                        text-decoration: underline;
                        color: #FFB000;
                        img {
                            margin-left: 10px;
                            vertical-align: unset;
                            width: 7px;
                        }
                    }
                }
                .promotion {
                    margin-top: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    img {
                        margin-top: 5px;
                        width: 278px;
                    }
                    p {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 129.99%;
                        &.accent {
                            font-weight: 600;
                            color: #FFB000;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) {
.content-wrapper {
    .wrap{
        width: 100%;
        padding: 60px 0px 30px 0px;
        h1 {
            width: 90%;
            word-break: keep-all;
            font-size: 28px;
            margin-bottom: 40px;
        }
        .step-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 0px 38px;
            gap: 15px;
            margin-bottom: 15px;
            justify-content: center;
            .next {
                display: inline-block;
            }
            .box { 
                padding: 30px;
                width: 100%;
                height: unset;
                min-height: 285px;
                .label {
                    width: fit-content;
                    padding: 1px 15px;
                    font-size: 16px;

                }
                .step {
                    margin-top: 20px;
                    font-size: 16px;
                }
                h2 {
                    font-size: 20px;
                    margin-top: 10px;
                }
                & > p {
                    margin-top: 20px;
                    font-size: 10px;
                }
                .link {
                    & > * {
                        font-size: 14px; 
                    }
                }
                .promotion {
                    img {
                        margin-top: 5px;
                        width: 210px;
                        max-width: 100%;
                    }
                    p {
                        font-size: 14px;
                        &.accent {
                            color: #FFB000;
                        }
                    }
                }
            }
        }
    }
}
}
</style>