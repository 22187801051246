<template>
    <div class="blog-tab">
        <div v-for="(tab, index) of list" :key="index" :class="{ active: tab == activeTab }" @click="$emit('change', tab)">
            {{ tt(tab.name) }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogTab',
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        activeTab: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {}
    },
    watch: {},
    created() {},
    methods: {},
}
</script>

<style lang="scss" scoped>
.blog-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    div {
        padding-bottom: 15px;
        font-size: 1.125rem;
        color: #666;
        cursor: pointer;
        &:hover {
            border-bottom: 4px solid #eee;
            margin-bottom: -4px;
        }
        &.active {
            border-bottom: 4px solid #fec741;
            margin-bottom: -4px;
            font-weight: 700;
            color: #111;
        }
    }
}
@media (max-width: 767px) {
    .blog-tab {
        justify-content: space-evenly;
        gap: 0;
        padding: 10px 0;
        box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        div {
            padding: 9px 13px;
            font-weight: 700;
            font-size: 0.813rem;
            border-radius: 30px;
            background: #fff;
            color: #111;
            &:hover,
            &.active {
                border: none !important;
                margin: 0 !important;
            }
            &.active {
                background: #fec741;
                color: #fff;
            }
        }
    }
}
</style>
