<template>
    <div class="estimate-wrap">
        <button class="backBtn" @click="goBackPrice">
            <img :src="require('@/assets/svg/goBack.svg')">
            <span>
                요금 안내 페이지로 돌아가기
            </span>
        </button>
        <div class="logo-wrap">
            <img :src="require('@/assets/image/estimate/labmanager.png')">
            라이선스 견적서 다운로드
        </div>
        <div class="button-wrap">
            <button class="yellow" @click="downloadPdf">PDF 다운로드</button>
            <button @click="printHtml">바로 인쇄</button>
        </div>
        <div class="estimate" ref="estimate">
            <div
			style="
                margin: 0;
				padding: 0;
				background-color: #fff;
				width: 210mm;
				height: 297mm;
				overflow: hidden;
				text-align: center;
				line-height: normal;
				position: relative;
				background-color: #fff;
				width: 100%;
				height: 842px;
				overflow: hidden;
				text-align: left;
				font-size: 12px;
				color: #000;
				font-family: Pretendard;
				width: 600px;
				margin: auto;
			    "
            >
                <b style="position: absolute; top: 71px; left: 0px; font-size: 32px; line-height: 129.99%">라이선스 견적서</b>
                <div style="top: 122px; left: 0px; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; gap: 5px; font-size: 14px; position: absolute">
                    <div style="position: relative; line-height: 129.99%">견적 작성일자</div>
                    <div style="position: relative; line-height: 129.99%"><b>{{ today }}</b></div>
                </div>
                <div style="top: 142px; left: 0px; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; gap: 5px; font-size: 14px; position: absolute">
                    <div style="position: relative; line-height: 129.99%">견적 유효기간</div>
                    <div style="position: relative; line-height: 129.99%"><b>{{ `${expirationDay} (견적일로부터 30일)` }}</b></div>
                </div>
                <div style="position: absolute; top: 75px; left: 388px; width: 200px; height: 80px">
                    <b style="line-height: 129.99%; font-size: 14px">공급자 정보</b>
                    <div style="border-top: 1px solid #000; box-sizing: border-box; width: 180px; height: 1px"></div>
                    <div
                        style="
                            position: absolute;
                            left: -15px;
                            width: 160px;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 5px;
                            display: flex;
                            justify-content: space-between;
                            -webkit-transform: scale(0.8);
                        "
                    >
                        <div style="align-items: center; gap: 8px; display: flex; width: 200px; flex-direction: row; justify-content: space-between">
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%; font-weight: 500">상호명</div>
                                <div style="position: relative; line-height: 129.99%; font-weight: 300; flex-shrink: 0; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2">주식회사 스마트잭</div>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%; font-weight: 500; white-space: nowrap">대표</div>
                                <div style="font-weight: 300; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; position: relative; line-height: 129.99%">김건우</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                            <div style="position: relative; line-height: 129.99%; font-weight: 500; white-space: nowrap">사업자등록번호</div>
                            <div style="font-weight: 300; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; position: relative; line-height: 129.99%">630-86-00974</div>
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                            <div style="position: relative; line-height: 129.99%; font-weight: 500; white-space: nowrap">주소</div>
                            <div style="position: relative; line-height: 129.99%; font-weight: 300; display: inline-block; flex-shrink: 0">서울 성동구 성수일로4가길 10, 3층</div>
                        </div>
                        <div style="width: 165px; align-items: center; gap: 8px; display: flex; width: 200px; flex-direction: row; justify-content: space-between">
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%; font-weight: 500; white-space: nowrap">업태</div>
                                <div style="font-weight: 300; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; position: relative; line-height: 129.99%">정보통신업</div>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%; font-weight: 500; white-space: nowrap">종목</div>
                                <div style="font-weight: 300; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; position: relative; line-height: 129.99%">소프트웨어 개발</div>
                            </div>
                        </div>
                    </div>
                    <img style="position: absolute; top: 42px; left: 137px; width: 27px; height: 25px; object-fit: cover" alt="" src="@/assets/image/estimate/stamp.png" />
                </div>
				<h4 style="position: absolute; top: 260px; left: 0px; font-weight: 600; font-size: 16px;">
					{{ estimate.instituteName }} 귀하
				</h4>
				<h6 style="position: absolute; top: 300px; left: 0px; font-size: 10px;">
					아래와 같이 견적합니다.
				</h6>
                <div style="position: absolute; top: 340px; left: 0px; gap: 40px; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start">
                    <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; gap: 20px">
                        <div style="position: relative; width: 590px; height: 30px; padding-top: 10px; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start">
                            <div style="width: 150px; position: relative; line-height: 129.99%; font-weight: 600">상품명</div>
                            <div style="width: 75px; position: relative; line-height: 129.99%; font-weight: 600">기간</div>
                            <div style="width: 75px; position: relative; line-height: 129.99%; font-weight: 600">수량</div>
                            <div style="width: 100px; position: relative; line-height: 129.99%; font-weight: 600">단가</div>
                            <div style="width: 100px; position: relative; line-height: 129.99%; font-weight: 600">공급가액</div>
                            <div style="width: 90px; position: relative; line-height: 129.99%; font-weight: 600">세액</div>
                            <div style="width: 590px; position: absolute; top: -0.5px; left: -0.5px; border-top: 1px solid #000; box-sizing: border-box; height: 1px"></div>
                            <div style="width: 590px; top: 29.75px; left: -0.25px; border-top: 0.5px solid #000; height: 0.5px; position: absolute; box-sizing: border-box"></div>
                        </div>
                        <div style="width: 590px; display: flex; flex-direction: row; align-items: center; justify-content: flex-start;font-size: 14px">
                            <div style="width: 150px; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; font-size: 12px">
                                <div style="position: relative; line-height: 129.99%; word-break: keep-all">랩매니저 PRO 라이선스</div>
                                <div style="position: relative; line-height: 129.99%; font-weight: 600">{{ typeText }}</div>
                            </div>
                            <div style="width: 75px; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; gap: 4px">
                                <div style="position: relative; line-height: 129.99%; ">{{ `${estimate.month}개월` }}</div>
                            </div>
                            <div style="width: 75px; position: relative; line-height: 129.99%; display: inline-block">{{ `${estimate.count == 99999 ? '무제한' : estimate.count + '개'}` }}</div>
                            <div style="width: 100px; position: relative; line-height: 129.99%; word-break: break-all">{{ `${formatNumberLocale(price)}원` }}</div>
                            <div style="width: 100px; position: relative; line-height: 129.99%; word-break: break-all">{{ `${formatNumberLocale(price * (estimate.count == 99999 ? 1 : estimate.count) * estimate.month)}원` }}</div>
                            <div style="width: 90px; position: relative; line-height: 129.99%; word-break: break-all">{{ `${formatNumberLocale(price * (estimate.count == 99999 ? 1 : estimate.count) * estimate.month / 10)}원` }}</div>
                        </div>
                        <div style="width: 590px; position: relative; border-top: 1px solid #000; box-sizing: border-box; height: 1px"></div>
                    </div>
                    <div style="flex-direction: column; gap: 5px; font-size: 20px; align-items: flex-start; display: flex; justify-content: flex-start">
                        <div style="align-items: flex-start; gap: 10px; display: flex; flex-direction: row; justify-content: flex-start">
                            <div style="position: relative; line-height: 129.99%; font-weight: 600">견적 최종 금액</div>
                            <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: 5px">
                                <div style="position: relative; line-height: 129.99%">
                                    <span style="font-weight: 800">{{ formatNumberLocale((price * (estimate.count == 99999 ? 1 : estimate.count) * estimate.month * 1.1))}}</span>
                                    <span>원</span>
                                </div>
                                <div style="position: relative; font-size: 16px; line-height: 129.99%">(VAT+)</div>
                            </div>
                        </div>
                        <div style="font-size: 12px; align-items: flex-start; gap: 10px; display: flex; flex-direction: row; justify-content: flex-start">
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%">공급가 합계</div>
                                <div style="position: relative; line-height: 129.99%">{{ `${formatNumberLocale(price * (estimate.count == 99999 ? 1 : estimate.count) * estimate.month)}원` }}</div>
                            </div>
                            <div style="display: flex; flex-direction: row; align-items: flex-start; justify-content: space-between; gap: 5px">
                                <div style="position: relative; line-height: 129.99%">세액 합계</div>
                                <div style="position: relative; line-height: 129.99%">{{ `${formatNumberLocale(price / 10 * (estimate.count == 99999 ? 1 : estimate.count) * estimate.month)}원` }}</div>
                            </div>
                        </div>
                    </div>
                    <div style="gap: 20px; font-size: 12px; display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start">
                        <div style="position: relative; border-top: 0.5px solid #d3d3d3; box-sizing: border-box; width: 488.5px; height: 0.5px"></div>
                        <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; gap: 10px">
                            <div style="position: relative; line-height: 129.99%; font-weight: 600">견적 관련 안내사항</div>
                            <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: flex-start; gap: 2px">
                                <div style="position: relative; line-height: 129.99%">- 계좌이체로 결제 시 전자세금계산서, 신용카드로 결제 시 카드 매출전표가 발급됩니다.</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="position: absolute; top: 756px; left: 0px; display: flex; flex-direction: row; align-items: flex-start; justify-content: flex-start; gap: 15px">
                    <img style="position: relative; width: 170px; height: 15px" alt="" src="@/assets/image/estimate/labmanager.png" />
                    <div style="position: relative; border-right: 0.5px solid #d3d3d3; box-sizing: border-box; width: 0.5px; height: 15.5px"></div>
                    <img style="position: relative; width: 150px; height: 15px" alt="" src="@/assets/image/estimate/pro.png" />
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import html2pdf from 'html2pdf.js'
    export default {
        name: 'EstimateViewForm',
        computed: {
            today() {
                return new Date().toLocaleDateString()
            },
            expirationDay() {
                let now = new Date()
                return new Date(now.setDate(now.getDate() + 30)).toLocaleDateString()
            },
            price() {
                return this.estimate.type == 'EDUCATION' ? 25000 : this.estimate.plan == 'YEAR' ? 50000 : 60000
            },
            estimate() {
                return this.$route.query
            },
			typeText() {
				return  this.estimate.type == 'EDUCATION' ? "대학 무제한" : "STANDARD"
			}
        },
        methods: {
            goBackPrice() {
                window.close()
            },
            formatNumberLocale(num) {
                let numVal = Number(num)
                if (typeof numVal === 'number') return numVal.toLocaleString()
                else return num
            },
            downloadPdf() {
                html2pdf(this.$refs.estimate, {
                    margin: 0,
                    filename: this.typeText + '_견적서.pdf',
                    image: { type: 'jpg', quality: 0.95 },
                    html2canvas: {
                        scale: 1.5,
                        dpi: 192,
                    },
                    jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4', compressPDF: true },
                })
                
            },
            printHtml() {
                const printArea = this.$refs.estimate
                if(printArea) {
                    const printWindow = window.open('', '_blank');
                    printWindow.document.open();
                    printWindow.document.write(`
                    <html>
                        <head>
                        <title>Print</title>
                        </head>
                        <body>
                        ${printArea.innerHTML}
                        </body>
                    </html>
                    `);
                    printWindow.document.close();
                    printWindow.print();
                    setTimeout(() => {
                        printWindow.close();
                    }, 100)
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.estimate-wrap {
    padding: 100px 150px;
    width: 1080px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: auto;
    .backBtn {
        text-align: center;
        width: fit-content;
        padding: 5px 15px;
        align-items: center;
        border: 1px solid #d3d3d3;
        border-radius: 5px;
        & > img {
            margin-right: 15px;
            margin-bottom: 3px;
        }
        /* position: relative;
        &::before {
            width: 20px;
			height: 20px;
			background-color: #fff;
			content: url(~@/assets/image/icon-question.svg);
			position: absolute;
			top: 4px;
			left: 0px;
        } */
    }
    .logo-wrap {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-weight: 700;
        font-size: 24px;
        img {
            width: 210px;
        }
    }
    .button-wrap {
        display: flex;
        gap: 20px;
        button {
            border-radius: 5px;
            padding: 5px 15px;
            border: 1px solid #d3d3d3;
            font-weight: 700;
            text-align: center;
            &.yellow {
                background: #fec741;
                border: none;
            }
        }
    }
    .estimate {
        /* width: 707px;
        height: 1080px; */
        border: 1px solid #d3d3d3;
    }
}
</style>
