<template>
    <div id="Voucher">
		<intro-layout>
			<template #intro-content>
                <div class="header-voucher">
                    <div class="title">
                        <img src="@/assets/image/logo-voucher.png" />
                        <h1>
                            70% 할인된 금액으로<br />연구실 관리를 시작해 보세요.
                        </h1>
                        <p>지금 바로 무료사용을 시작하세요!<br/>부담은 줄이고, 연구실 안전과 효율은 높여보세요.</p>
                        <button @click="goVoucher">비대면 바우처 신청 바로가기 <img src="@/assets/image/icon-arrow-black.svg" /></button>
                    </div>
                    <img class="sale-image" src="@/assets/image/image-voucher-sale.png" />
            </div>
			</template>
		</intro-layout>
        <Intro />
        <Howtouse />
        <Why />
        <Apply />
        <Price />
        <div class="voucher-footer">
            <a href="#" @click="$root.openApp">무료사용</a>
            <a href="https://www.lab-manager.com/request">문의하기</a>
        </div>
    </div>
</template>
<script>
import IntroLayout from '@/ui/page/IntroLayout'
import Price from '@/components/voucher/price'
import Why from '@/components/voucher/why'
import Intro from '@/components/voucher/intro'
import Howtouse from '../components/voucher/howtouse'
import Apply from '../components/voucher/apply.vue'
export default {
    name: 'Voucher',
	components: {
		IntroLayout,
        Price,
        Intro,
        Howtouse,
        Apply,
        Why,
    },
	metaInfo: {
		title: '비대면바우처_랩매니저 PRO',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인, 비대면바우처, 비용 70% 정부지원, 정부지원사업' },
			{vmid:'description', name:'description', content: '랩매니저 PRO, 비대면 바우처 사용하고 70% 할인된 가격으로 만나보세요. 비대면 바우처 이용고객도 머크 10% 할인 가능! 부담은 줄이고, 연구실 안전과 효율은 높여보세요.' },
			{vmid:'og:description', property:'description', content: '랩매니저 PRO, 비대면 바우처 사용하고 70% 할인된 가격으로 만나보세요. 비대면 바우처 이용고객도 머크 10% 할인 가능! 부담은 줄이고, 연구실 안전과 효율은 높여보세요.' },
		]
	},
    methods: {
        goVoucher() {
            window.open('https://k-voucher.kr/page/productSearch?mode=update&seq=2050')
        },
        
    }
}
</script>
<style lang="scss" scoped>
#Voucher {
	.intro {
        background-color: white;
		padding-top: 100px;
		height: 726px;
        .header-voucher {
            width: 100%;
            height: 626px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            // flex-wrap: wrap;
            .title {
                width: 50%;
                padding-bottom: 120px;
                img {
                    height: 40px;
                }
                h1 {
                    font-weight: 800;
                    font-size: 40px;
                    line-height: 129.99%;
                    margin-top: 50px;
                }
                p {
                    margin-top: 30px;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 129.99%;
                }
                button {
                    width: 375px;
                    height: 50px;
                    margin-top: 50px;
                    background: #FEC741;
                    border-radius: 10px;
                    font-size: 24px;
                    font-weight: 700;
                    color: black;
                    img {
                        height: 16px;
                        margin-left: 10px;
                    }
                }
            }
            .sale-image {
                vertical-align: bottom;
                width: 686px;
                height: fit-content;
            }
        }
	}

    .voucher-footer{
        z-index: 20;
        display: flex;
        justify-content: center;
        position: fixed;
        gap: 10px;
        bottom: 0px;
        width: 610px;
        left: calc(50% - 305px);
        a {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(50% - 5px);
            height: 60px;
            background: linear-gradient(180deg, #FEC741 0%, #FFA90B 100%);
            border-radius: 10px 10px 0px 0px;
            font-family: 'SUITE';
            font-style: normal;
            font-weight: 900;
            font-size: 22px;
            line-height: 129.99%;
            color: white;
        }
    }
}
@media (max-width: 1024px) {
    #Voucher {
        .intro {
            height: fit-content;
			align-items: flex-start;
			padding-top: 100px;
            .header-voucher {
                height: fit-content;
                width: 100%;
                display: block;
                text-align: center;
                // flex-wrap: wrap;
                .title {
                    padding-top: 60px;
                    width: 100%;
                    padding-bottom: 50px;
                    img {
                        height: 23px;
                    }
                    h1 {
                        font-size: 28px;
                        margin-top: 30px;
                    }
                    p {
                        margin-top: 20px;
                        font-size: 17px;
                    }
                    button {
                        width: 265px;
                        height: 40px;
                        margin-top: 25px;
                        border-radius: 10px;
                        font-size: 17px;
                        img {
                            height: 13px;
                        }
                    }
                }
                .sale-image {
                    width: 100%;
                    height: unset;
                }
            }
        }
        .voucher-footer{ 
            width: 100%;
            left: 0px;
        }
    }
}
</style>