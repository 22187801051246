import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Service from '@/views/Service.vue'
import Request from '@/views/Request.vue'
import Survey from '@/views/Survey.vue'
import Investigation from '@/views/Investigation.vue'
import Price from '@/views/Price.vue'
import Around from '@/views/Around.vue'
//개인정보 수집 페이지
import GatheringForm from '@/views/GatheringForm.vue'
import GatheringPictureForm from '@/views/GatheringPictureForm.vue'

import Event from '@/views/event/Event.vue'
import Event2 from '@/views/event/Event2.vue'
import Blog from '@/views/blog/Blog.vue'
import BlogDetail from '@/views/blog/BlogDetail.vue'

// EVENT : 2023 : 01 - KOREA Lab
import Event2023 from '@/views/event/01/Event.vue'
import Event2023Request from '@/views/event/01/Request.vue'
import Event2023Complete from '@/views/event/01/Complete.vue'

import PromotionM10 from '@/views/PromotionM10'
import Campaign01 from '@/views/campaign/campaign01.vue'
import Campaign02 from '@/views/campaign/campaign02.vue'

import Voucher from '@/views/Voucher'
import Estimate from '@/components/form/EstimateViewForm.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '*',
		name: 'Home',
		component: Home,
	},
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/service',
		name: 'Service',
		component: Service,
	},
	{
		path: '/support',
		redirect: '/',
	},
	{
		path: '/request',
		name: 'Request',
		component: Request,
	},
	{
		path: '/survey',
		name: 'Survey',
		component: Survey,
	},
	{
		path: '/investigation',
		name: 'Investigation',
		component: Investigation,
	},
	{
		path: '/price',
		name: 'Price',
		component: Price,
	},
	{
		path: '/pro',
		// name: 'Pro',
		// component: Pro,
		redirect: '/',
	},
	{
		path: '/around/:key/:value',
		name: 'Around',
		component: Around,
	},
	{
		path: '/gatheringForm',
		name: 'GatheringForm',
		component: GatheringForm,
	},
	{
		path: '/gatheringPictureForm',
		name: 'GatheringPictureForm',
		component: GatheringPictureForm,
	},
	{
		path: '/event',
		name: 'Event',
		component: Event,
	},
	{
		path: '/event/discount',
		name: 'Event2',
		component: Event2,
	},
	{
		path: '/blog/:category',
		name: 'Blog',
		component: Blog,
	},
	{
		path: '/blog/:category/:id',
		name: 'BlogDetail',
		component: BlogDetail,
	},
	{
		path : '/event/2023/01',
		name : 'Event2023_01',
		component : Event2023
	},
	{
		path : '/event/2023/01/request',
		name : 'Event2023_02',
		component : Event2023Request
	},
	{
		path : '/event/2023/01/complete',
		name : 'Event2023_03',
		component : Event2023Complete
	},
	{
		path: '/promotion/m10',
		name: 'PromotionM10',
		component: PromotionM10,
	},
	{
		path: '/promotion/1',
		name: 'Campaign01',
		component: Campaign01,
	},
    {
		path: '/promotion/2',
		name: 'Campaign02',
		component: Campaign02,
	},
	{
		path: '/voucher',
		name: 'Voucher',
		component: Voucher,
	},
	{
		path: '/estimate',
		name: 'Estimate',
		component: Estimate,
	}
]

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
})
router.beforeEach((to, from, next) => {
	const toQueryLength = Object.keys(to.query).length
	const fromQueryLength = Object.keys(from.query).length
	if (toQueryLength < fromQueryLength) {
		next({
			path: to.path,
			query: from.query,
		})
	} else next()
})

export default router
