<template>
    <div ref="wrapper" class="carousel-wrapper" :class="{yellow: isYellow}">
		<slot name="index">
		</slot>
        <p v-if="title">{{ title }}</p>
        <Carousel v-model="slide" :spacePadding="padding" :paginationEnabled="!noIndicator" :perPage="perPage" v-bind="options">
			<slide v-for="(item, index) in list" :key="index">
                <div class="content_wrapper" :style="{width: `${width}px`}" style="display: flex; gap: 30px" @click="e => $emit('click',e )">
                    <div v-if="item.content" class="content_wrap" style="display: flex; flex-direction: column" v-html="item.content">
                    </div>
				    <img v-for="(img, index) in item.images" :key="`img_${index}`" :src="img" />
                </div>
			</slide>
		</Carousel>
    </div>
</template>
<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
	components: {
		Carousel,Slide
	},
    props: {
		noIndicator : {
			type: Boolean,
			default: false,
		},
        list: Array,
        title: String,
        isYellow: {
            type: Boolean,
            default: false,
        },
		perPage: {
			type: Number,
			default: 2,
		},
        options: {
            type: Object,
            default: null,
        },
        padding: {
            type: Number,
            default: 24,
        },
    },
	watch: {
		list(){
			this.slide = 0
		},
        slide: {
			handler(index) {
                this.$emit('actionSlide', index)
			},
            immediate: true,
		},
	},
	mounted() {
		this.width = (this.$refs['wrapper'].offsetWidth - 40 - 30) / this.perPage
	},
    data(){
        return {
			width: 0,
            slide: 0,
        }
    },
}
</script>
<style lang="scss" scoped>
.carousel-wrapper {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &.yellow {
        background-color: #FDB929;
    }
}
p {
    // font-family: 'SUITE', sans-serif;
    border: 2px solid #000000;
    border-radius: 20px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 30px;
}
::v-deep {
	.VueCarousel {
		width: 100%;
		.VueCarousel-slide {
			display: flex;
			justify-content: center;
			text-align: center;
			align-items: baseline;
			// flex-basis: unset;
			// gap: 15px;
		}
	}
}
</style>