import Base from './Base.js'

export default class Client extends Base {
	constructor(axios, cookies) {
		super(axios, cookies)
	}

	async submitRequest(form) {
		try {
			const response = await this.axios.post('/landing/feedback', form)
			return response.data
		} catch (e) {
			return e.response.data
		}
	}

	async submitInquiry(form) {
		try {
			const response = await this.axios.post('/marketing/221031', form)
			return response.data
		} catch (e) {
			return e.response.data
		}
	}

	async fetchBoardList(category, offset, length) {
		try {
			const response = await this.axios.get(`admin/landing/board/${category}/list`, {
				params: {
					offset: offset,
					length: length,
				},
			})
			return response.data
		} catch (error) {
			console.log(error)
		}
	}

	async fetchBoard(id) {
		try {
			const response = await this.axios.get(`admin/landing/board/${id}`)
			return response.data
		} catch (error) {
			console.log(error)
		}
	}
}
