<template>
	<div class="form-input">
		<div class="input-box">
			<span class="title">{{ title }}<span class="required" v-if="required">*</span></span>
			<input :type="type" :value="value" @input="inputValue" :maxlength="isPhone ? 13 : 30" />
		</div>
		<p class="content" v-if="content">{{ content }}</p>
	</div>
</template>

<script>
export default {
	name: 'FormInput',
	props: {
		title: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		content: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		mode: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
	},
	computed: {
		isPhone() {
			return this.mode == 'phone'
		},
	},
	methods: {
		inputValue(e) {
			if (this.isPhone) {
				this.$emit('input', this.autoHypen(e.target.value))
			} else {
				this.$emit('input', e.target.value)
			}
		},
		autoHypen(str) {
			str = str.replace(/[^0-9]/g, '')
			let tmp = ''

			if (str.substring(0, 2) == '02') {
				// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
				if (str.length < 3) {
					return str
				} else if (str.length < 6) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2)
					return tmp
				} else if (str.length < 10) {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 3)
					tmp += '-'
					tmp += str.substr(5)
					return tmp
				} else {
					tmp += str.substr(0, 2)
					tmp += '-'
					tmp += str.substr(2, 4)
					tmp += '-'
					tmp += str.substr(6, 4)
					return tmp
				}
			} else {
				// 핸드폰 및 다른 지역 전화번호 일 경우
				if (str.length < 4) {
					return str
				} else if (str.length < 7) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3)
					return tmp
				} else if (str.length < 11) {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 3)
					tmp += '-'
					tmp += str.substr(6)
					return tmp
				} else {
					tmp += str.substr(0, 3)
					tmp += '-'
					tmp += str.substr(3, 4)
					tmp += '-'
					tmp += str.substr(7)
					return tmp
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.form-input {
	width: calc(100% - 20px);
	margin: 0 10px 20px;
	.input-box {
		width: 100%;
		border: 1px solid #ddd;
		border-radius: 8px;
		height: 56px;
		padding: 10px 14px;
		.title {
			font-size: 0.75rem;
			font-weight: 400;
			color: #999;
			display: block;
		}
		.required {
			display: inline-block;
			color: #ff5746;
		}
		input {
			width: 100%;
			height: 20px;
			border: 0;
		}
	}
	.content {
		width: 100%;
		margin-top: 4px;
		font-size: 0.75rem;
		font-weight: 400;
		color: #cacbcb;
		text-align: left;
	}
}
</style>
