<template>
    <div class="document-list">
        <base-list
            :title="title"
            :subTitle="subTitle"
            :showAll="showAll"
            :totalCount="count"
            :pageCount="5"
            @expand="$emit('expand')"
            @page="$emit('page', $event)"
        >
            <div class="list">
                <document-item v-for="(item, index) of list" :key="index" :item="item" @click="$emit('click', $event)" :isLoading="isLoading" />
                <div class="no-data" v-if="!count && !isLoading">
                    <p v-html="tt('게시글이 존재하지 않습니다.<br>빠른 시일 내로 업데이트하도록 하겠습니다.')"></p>
                </div>
            </div>
        </base-list>
    </div>
</template>

<script>
import BaseList from '@/ui/page/BaseList.vue'
import DocumentItem from '@/ui/page/DocumentItem.vue'

export default {
    name: 'DocumentList',
    components: {
        BaseList,
        DocumentItem,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        subTitle: {
            type: String,
            default: '',
        },
        showAll: {
            type: Boolean,
            default: true,
        },
        list: {
            type: Array,
            default: () => [],
        },
        count: {
            type: Number,
            default: 0,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.document-list {
    .list {
        display: flex;
        flex-direction: column;
        .document-item:first-child {
            padding-top: 0;
        }
    }
    .no-data {
        width: 100%;
        p {
            padding: 50px 0;
            text-align: center;
            width: 100%;
            font-size: 1.25rem;
            font-weight: 400;
        }
    }
}
@media (max-width: 767px) {
    .document-list {
        width: 100%;
        .no-data {
            width: 100%;
            p {
                padding: 30px 0;
                font-size: 1rem;
            }
        }
    }
}
</style>
