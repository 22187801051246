<template>
	<div id="Request2" style="max-width : 800px; margin : 0 auto;">
		<div class="intro">
			<img src="@/assets/image/event/01/request.png">
		</div>
		<request-form title="문의하기" style="width: 100%;"/>
	</div>
</template>

<script>
import RequestForm from '@/components/form/EventRequestForm'

export default {
	components: {
		RequestForm,
	},
}
</script>

<style lang="scss">
#Request2 {
	width: 100%;
	.intro {
		width: 100%;
		// height: 1038px;
		background: #fec741;
		position: relative;
		text-align: center;
		img {
			width : 700px;
		}
	}
}

@media (min-width: 768px) and (max-width: 1139px) {
	#Request2 {
		width: 100%;
		.intro {
			width: 100%;
			img {
				width : 100%;
			}
		}
	}
}

@media (max-width: 767px) {
	#Request2 {
		.intro {
			width: 100%;
			height: auto;
			img {
				width : 100%;
			}
		}
	}
}
</style>
