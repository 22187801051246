<template>
	<div id="container">
		<img v-show="current === 1" class="image" src="@/assets/image/event/01/event1.png">
		<img v-show="current === 2" class="image" src="@/assets/image/event/01/event2.png">
		<img v-show="current === 3" class="image" src="@/assets/image/event/01/event3.png">
		<div class="virtual-btn" id="btn1" @click="current=1"></div>
		<div class="virtual-btn" id="btn2" @click="current=2"></div>
		<div class="virtual-btn" id="btn3" @click="current=3"></div>
		<div class="virtual-btn" id="btn4" @click="moveToRequest"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			current : 1
		}
	},
	methods : {
		moveToRequest() {
			this.$router.push({
				name : "Event2023_02"
			})
		}
	}
}
</script>

<style lang="scss">
#container {
	position: relative;
	max-width: 800px;
	margin : 0 auto;
	width : 100%;
	.image {
		width: 100%;
	}
	.virtual-btn {
		position: absolute;
		width : 30%;
		height: 4%;
		background: transparent;
		cursor: pointer;
		&#btn1 {
			top :45.5%;
			left : 5%;
		}

		&#btn2 {
			top :45.5%;
			left : 35%;
		}

		&#btn3 {
			top :45.5%;
			left : 65%;
		}
		&#btn4 {
			top : 88% !important;
			left : 15%;
			height: 5%;
			width: 70%;
			// background: black;
		}
	}
}
</style>