<template>
	<div id="Request">
		<div class="intro">
			<div class="txtbox">
				<div class="icon">
					<img src="@/assets/image/icon-request.png" />
				</div>
				<h2 v-html="tt('궁금한 점이 있으신가요?<br />지금, 랩매니저에 대해 물어보세요!')"></h2>
				<p
					v-html="
						tt(
							'문의를 남겨주시면 랩매니저 팀이 순차적으로 답변해 드립니다.'
						)
					"
				></p>
			</div>
		</div>
		<request-form title="문의하기" />
	</div>
</template>

<script>
import RequestForm from '@/components/form/EventRequestForm'

export default {
	components: {
		RequestForm,
	},
}
</script>

<style lang="scss">	
#Request {
	width: 100%;
	.intro {
		width: 100%;
		height: 500px;
		background: #FEF9EC;
		position: relative;
		.txtbox {
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			.icon {
				width: 126px;
				margin: 0 auto;
				margin-bottom: 30px;
				img {
					width: 100%;
				}
			}
			h2 {
				font-size: 40px;
				padding-bottom: 26px;
				font-weight: 700;
			}
			p {
				font-size: 24px;
				line-height: 130%;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 1139px) {
	#Request {
		width: 100%;
		.intro {
			width: 100%;
			height: 350px;
			.txtbox {
				width: 100%;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				.icon {
					width: 80px;
					height: 80px;
					margin: 0 auto;
					margin-bottom: 20px;
					img {
						width: 100%;
					}
				}
				h2 {
					font-size: 2.8rem;
					padding-bottom: 15px;
				}
				p {
					font-size: 18px;
					line-height: 27px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	#Request {
		.intro {
			width: 100%;
			height: 380px;
			.txtbox {
				padding: 0px 30px;
				.icon {
					width: 70px;
					margin-bottom: 20px;
				}
				h2 {
					word-break: keep-all;
					font-size: 1.6rem;
					padding-bottom: 5px;
				}
				p {
					margin-top: 20px;
					word-break: keep-all;
					padding: 0px 60px;
					font-size: 0.95rem;
					line-height: unset;
				}
			}
		}
	}
}
</style>
