<template>
    <div class="function-layout">
        <div class="function-title" v-html="tt('자주 묻는 질문을<br />모아봤어요!')"></div>
        <div class="function-list" role="tablist">
            <div class="faq-item" v-for="(item,index) in faqs" :key="index" v-b-toggle="'panel'+index" @click="clickItem(index)">
                <div class="up" rol="tab">
                    <div>
                        <div class="label">{{ item.label }}</div>
                        <div class="title"> {{  item.title }}</div>
                    </div>
                    <img class="arrow" :class="{show: showId == index}" src="@/assets/image/icon-arrow-yellow.svg" />
                </div>
                <b-collapse :id="'panel'+index" accordion="tabpanel" class="down" role="tabpanel">
                    {{ item.content }}
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        faqs() {
            return [
                {
                    label: '보안 관련',
                    title: '개인 및 연구실 정보는 안전하게 보호되나요?',
                    content: '랩매니저는 암호화된 통신 방식(SSL, TSL 연결)을 사용하여 고객 데이터를 안전하게 관리하고 있습니다. 또한 제3자에게 제공되는 정보 역시 일정기간 간격으로 파기를 확인하고 있으며 개인정보 보호 서약서를 받고 있습니다.'                    
                },
                {
                    label: 'MSDS 관련',
                    title: '등록하는 모든 물품의 MSDS를 확인할 수 있나요?',
                    content: '현재 랩매니저 PRO에서는 70만종의 제조사 MSDS 데이터를 확인할 수 있으며, 꾸준히 최신화 및 그 종류를 업데이트 하고 있습니다. 다만 물품의 종류가 너무 많아 모든 물품의 MSDS를 확인하기는 어렵습니다. 자동 매칭되는 MSDS가 없을 시 수동으로 MSDS 파일 업로드가 가능합니다.'                    
                },
                {
                    label: '물품등록 관련',
                    title: '시약 이외의 초자기구, 연구물품도 등록할 수 있나요?',
                    content: '물품 등록 시 카테고리(화학물질/분석용 칼럼/연구장비/보호구/소모품)를 선택할 수 있으며, 기본으로 제공되는 카테고리 외에 필요한 카테고리는 추가 세팅도 가능합니다.'                    
                },
                {
                    label: '물품등록 관련',
                    title: '시약 라벨 촬영을 통한 시약 등록 시, 분석 후 등록까지 며칠이 소요되나요?',
                    content: '영업일 기준 평균 1~2일 정도 소요됩니다. 등록하려는 시약의 개수가 많거나 타 기관의 등록 양이 특정 시간에 몰리는 경우 등록까지 시간이 조금 더 소요될 수 있습니다.'                    
                },
                {
                    label: '물품등록 관련',
                    title: '시약 QR코드･바코드 스캔을 통한 물품 등록 시 모든 제조사 물품이 스캔 가능한가요?',
                    content: '현재 QR코드･바코드 스캔 등록은 Sigma-Aldrich, TCI, SAMCHUN, Duksan 등 총 16개 제조사 물품을 지원하고 있습니다. QR코드에 물품 정보를 입력하고 있는 제조사의 물품은 모두 스캔 가능합니다.'                    
                },
                {
                    label: '기관관리 관련',
                    title: '한 명의 연구원이 여러 기관에 소속될 수 있나요?',
                    content: '한 명의 연구원은 하나의 기관에만 소속될 수 있습니다. 만약 새로운 기관의 초대를 받아 승인하게 되면 기존에 소속된 연구실에서 자동 내보내기 처리됩니다.'                    
                }
            ]
        }
    }, 
    data() {
        return {
            showId: null,
            collapsingId: null
        }
    },
    methods: {
        clickItem(index) {
            if(this.showId == index)
                this.showId = null
            else {
                this.showId = index
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.function-layout {   
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    padding: 90px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        width: 800px;
    }
    .function-title {
        width: 500px;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        color: #000;
    }
    .function-list {
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .faq-item {
            display: flex;
            flex-direction: column;
            border: 1px solid #d3d3d3;
            border-radius: 20px;
            padding: 30px 100px;
            width: 800px;
            // gap: 25px;
            min-height: 140px;
                cursor: pointer;
                &:hover {
                    background-color: #fafafc;
                }
            .up {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                .label {
                    margin-bottom: 20px;
                    border-radius: 3px;
                    width:fit-content;
                    padding: 4px 10px;
                    color: #000;
                    font-size: 16px;
                    font-weight: 900;
                    background-color: #fec741;
                }
                .title {
                    font-weight: 600;
                    font-size: 24px;
                }
                .arrow {
                    transition: 0.3s;
                    &.show {
                        transform: rotate(90deg);
                    }
                }
            }
            .down {
                &::before {
                    content: '';
                    display: block;
                    border-top: 0.5px solid #ccc;
                    width: 100%;
                    height: 0px;
                    margin-bottom: 25px;
                    margin-top: 25px;
                }
                font-weight: 400;
                font-size: 20px;
                line-height: 129.99%;
                word-break: keep-all;
            }
        }
    }
}
@media (max-width: 767px) {
.function-layout {   
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;
    padding: 60px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        width: 100%;
    }
    .function-title {
        width: 200px;
        text-align: center;
        font-size: 28px;
        font-weight: 700;
        color: #000;
    }
    .function-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .faq-item {
            display: flex;
            flex-direction: column;
            border: 1px solid #d3d3d3;
            border-radius: 10px;
            padding: 20px 30px;
            width: 100%;
            // gap: 25px;
            min-height: 108px;
            cursor: pointer;
            &:hover {
                background-color: #fafafc;
            }
            .up {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 40px;
                .label {
                    margin-bottom: 10px;
                    border-radius: 3px;
                    width:fit-content;
                    padding: 4px 10px;
                    color: #000;
                    font-size: 12px;
                    font-weight: 900;
                    background-color: #fec741;
                }
                .title {
                    word-break: keep-all;
                    font-weight: 600;
                    font-size: 15px;
                }
                .arrow {
                    width: 23px;
                    transition: 0.3s;
                    &.show {
                        transform: rotate(90deg);
                    }
                }
            }
            .down {
                &::before {
                    content: '';
                    display: block;
                    border-top: 0.5px solid #ccc;
                    width: 100%;
                    height: 0px;
                    margin-bottom: 15px;
                    margin-top: 15px;
                }
                font-weight: 400;
                font-size: 13px;
                line-height: 129.99%;
                word-break: keep-all;
            }
        }
    }
}
}
</style>