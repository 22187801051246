<template>
  <div class="promotion-wrapper">
    <img src="@/assets/image/m10/edit-image-ep-01.png" />
    <img src="@/assets/image/m10/edit-image-ep-02.png" />
    <img src="@/assets/image/m10/edit-image-ep-03.png" />
    <img src="@/assets/image/m10/edit-image-ep-04.png" />
    <img src="@/assets/image/m10/edit-image-ep-05.png" />
    <div class="function-wrapper">
      <img class="logo" src="@/assets/image/logo-lmp.svg" />
      <h1>유용한 기능</h1>
      <Carousel :list="inventoryList" title="물품관리(재고관리)" />
      <Carousel isYellow :list="safetyList" title="안전관리" />
      <Carousel :list="purchaseList" title="구매관리" />
      <Carousel isYellow :list="budgetList" title="예산관리" />
    </div>
    <img src="@/assets/image/m10/image-04.png" />
    <img src="@/assets/image/m10/image-ep-05.png" />
    <img src="@/assets/image/m10/image-06.png" />
    <div class="promotion-footer">
      <a href="#" @click="$root.openApp">무료사용</a>
      <a href="https://www.lab-manager.com/request">문의하기</a>
    </div>
  </div>
</template>
<script>
import Carousel from "@/components/Carousel.vue";

export default {
  computed: {
    inventoryList() {
      return [
        require("@/assets/image/m10/기능-물품관리_01.png"),
        require("@/assets/image/m10/기능-물품관리_02.png"),
        require("@/assets/image/m10/기능-물품관리_03.png"),
      ];
    },
    safetyList() {
      return [
        require("@/assets/image/m10/기능-안전관리_01.png"),
        require("@/assets/image/m10/기능-안전관리_02.png"),
        require("@/assets/image/m10/기능-안전관리_03.png"),
      ];
    },
    purchaseList() {
      return [
        require("@/assets/image/m10/기능-구매관리_01.png"),
        require("@/assets/image/m10/기능-구매관리_02.png"),
      ];
    },
    budgetList() {
      return [require("@/assets/image/m10/기능-예산관리_01.png")];
    },
  },
  metaInfo: {
    title: "머크 전상품 10% 상시 할인_랩매니저 PRO",
    meta: [
      {
        vmid: "keywords",
        name: "keywords",
        content:
          "labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 머크 할인, MERCK, SIGMA-ALDRICH, 머크, 씨그마 알드리치",
      },
      {
        vmid: "description",
        name: "description",
        content:
          "랩매니저 PRO 유료 요금제 결제하고 머크 전상품 10% 할인 받아보세요! SIGMA-ALDRICH부터 SUPELCO까지 머크 전상품을 할인받고 구매하세요",
      },
    ],
  },
  components: { Carousel },
};
</script>
<style lang="scss" scoped>
.promotion-wrapper {
  width: 450px;
  margin: auto;

  & > img {
    width: 100%;
  }

  .promotion-footer {
    z-index: 20;
    display: flex;
    justify-content: center;
    position: fixed;
    gap: 10px;
    bottom: 0px;
    width: 450px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 5px);
      height: 60px;
      background: linear-gradient(180deg, #FD1631 -121.19%, #FECE3B 230.56%);
      border-radius: 10px 10px 0px 0px;
      font-family: "SUITE";
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 129.99%;
      color: white;
    }
  }
}

.function-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;

  .logo {
    width: 161px;
  }

  h1 {
    margin-top: 10px;
    font-family: "SUITE";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 129.99%;
    /* or 36px */

    text-align: center;

    color: #000000;
  }
}

@media (max-width: 767px) {
  .promotion-wrapper {
    width: 100%;

    .promotion-footer {
      width: 100%;
    }
  }
}
</style>