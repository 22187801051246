import axios from 'axios'

const sendMessage = async (body, title) => {
	delete body.title
	const result = await axios({
		method: 'post',
		url: 'https://7t4ght3xkax7zjglgrqds5g3xi0gphkh.lambda-url.ap-northeast-2.on.aws/?' + encodeURIComponent('https://hooks.slack.com/services/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'),
		data: {
			text: `[${title}]\n` + JSON.stringify(body, null, 2),
		},
		headers: {
			'Content-type': 'application/json',
		},
	})
	console.log(result)
	return result
}

export { sendMessage }
