<template>
    <div class="blog-detail">
        <div class="intro">
            <div>
                <span>{{ item.category ? tt(item.category.name) : '' }}</span>
                <b>{{ tt(item.title) }}</b>
                <p>{{ toStringByFormatting(item.create_datetime) }}</p>
            </div>
        </div>
        <div class="contents">
            <div class="text-wrap">
                <div v-html="item.content"></div>
            </div>
            <div class="btn-wrap">
                <button class="btn btn-yellow" @click="goList($route.params.category)">{{ tt('목록보기') }}</button>
            </div>
            <div v-if="nextItem || prevItem" class="route-wrap">
                <card-item v-if="nextItem" :item="nextItem" @click="onClickItem" />
                <card-item v-if="prevItem" :item="prevItem" @click="onClickItem" />
            </div>
        </div>
        <div class="inquiry">
            <div class="description">
                <p>
                    <b>{{ tt('추가로 문의하고싶은 내용이 있으신가요?') }}</b
                    ><br />
                    {{ tt('아래 문의하기에 남겨주시면 연락드리겠습니다.') }}
                </p>
            </div>
            <div class="btn-wrap">
                <button class="btn btn-yellow" @click="$router.push({ name: 'Request' })">{{ tt('문의하기') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import CardItem from '@/ui/page/CardItem.vue'

export default {
    name: 'BlogDetail',
    components: {
        CardItem,
    },
    data() {
        return {
            item: {},
            nextItem: null,
            prevItem: null,
            list: [],
        }
    },
    watch: {
        $route: {
            async handler(to) {
                this.item = await this.$service.client.fetchBoard(to.params.id)
                const response = await this.$service.client.fetchBoardList(to.params.category)
                this.list = response.list
                const index = this.list.findIndex(board => board.id === this.item.id)
                this.nextItem = this.list[index - 1] ?? null
                this.prevItem = this.list[index + 1] ?? null
            },
            immediate: true,
        },
    },
    methods: {
        onClickItem(id) {
            this.$router
                .push({
                    name: 'BlogDetail',
                    params: {
                        id,
                        category: this.$route.params.category,
                    },
                })
                .catch(() => {})
        },
        toStringByFormatting(date, delimiter = '.') {
            const dateTime = new Date(date)
            const year = dateTime.getFullYear()
            let month = dateTime.getMonth() + 1
            if (month < 10) {
                month = `0${month}`
            }
            let day = dateTime.getDate()
            if (day < 10) {
                day = `0${day}`
            }
            return [year, month, day].join(delimiter)
        },
        goList(category) {
            this.$router.push({
                name: 'Blog',
                params: {
                    category: category,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.blog-detail {
    .btn-wrap {
        margin-top: 40px;
        button {
            width: 200px;
            height: 60px;
            border-radius: 20px;
            font-size: 1.375rem;
        }
    }
    .intro {
        flex-wrap: wrap;
        width: 100%;
        background-color: #fec741;
        padding-top: 80px;
        padding-bottom: 91px;
        > div {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 1280px;
            margin: 0 auto;
            span {
                font-size: 1.125rem;
            }
            b {
                font-weight: 700;
                font-size: 1.5rem;
                display: block;
                word-break: keep-all;
            }
            p {
                font-size: 0.875rem;
            }
        }
    }
    .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 40px 0 102px 0;
        border-bottom: 1px solid #eee;
        .text-wrap {
            display: flex;
            flex-direction: column;
            gap: 40px;
            width: 1280px;
            padding: 62px 52px;
            border: 1px solid #eee;
            border-radius: 10px;
        }
        .route-wrap {
            display: flex;
            align-items: center;
            gap: 30px;
            margin-top: 102px;
        }
    }
    .inquiry {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 76px 0;
        .description {
            p {
                font-size: 1.25rem;
                text-align: center;
                b {
                    font-weight: 700;
                }
            }
        }
    }
}
@media (max-width: 767px) {
    ::v-deep .card-item {
        .img-wrap {
        }
        .contents {
            gap: 5px;
            height: 150px;
            .category {
                font-size: 0.625rem;
            }
        }
    }
    .blog-detail {
        .btn-wrap {
            button {
                padding: 10px 42px;
                border-radius: 8px;
                font-size: 1rem;
            }
        }
        .intro {
            padding: 50px 20px;
            > div {
                gap: 20px;
                width: 100%;
            }
        }
        .contents {
            padding-bottom: 50px;
            .text-wrap {
                width: 100%;
                padding: 5px 20px;
                border: none;
            }
            .route-wrap {
                margin-top: 50px;
                padding: 0 20px;
                display: block;
                > .card-item:first-child {
                    margin-bottom: 20px;
                }
            }
        }
        .inquiry {
            padding: 68px 0;
            .description {
                p {
                    font-size: 1rem;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.blog-detail {
    .contents {
        .text-wrap {
            p {
                font-size: 1rem;
                min-height: 20px;
            }
            > div {
                width: 100%;
            }
            img {
                max-width: 100%;
            }
        }
        ul {
            display: block;
            list-style-type: disc;
            padding-left: 20px;
            ul {
                list-style-type: circle;
                margin-block-start: 0px;
                margin-block-end: 0px;
            }
        }
        ol {
            display: block;
            list-style-type: decimal;
            padding-left: 20px;
        }
    }
}
</style>
