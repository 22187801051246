<template>
    <div class="promotion-wrapper">
        <div class="intro">
            <div>
                <img src="@/assets/svg/campaign02/title-01.svg" />
                <img src="@/assets/svg/campaign02/title-02.svg" />
                <h6>스크롤해서 비용절감 알아보기</h6>
                <img class="scroll" src="@/assets/svg/campaign02/scroll.svg" />

            </div>
            <img class="title" src="@/assets/image/campaign02/title_01.gif">
        </div>
		<img src="@/assets/image/campaign02/image-02.png" />
		<img src="@/assets/image/campaign02/image-03.png" />
		<img src="@/assets/image/campaign02/image-04.png" />
		<img src="@/assets/image/campaign02/image-05.png" />
		<img src="@/assets/image/campaign02/image-06.png" />
		<img src="@/assets/image/campaign02/image-07.png" />
		<img src="@/assets/image/campaign02/image-08.png" />
        <button @click="$root.openApp">랩매니저로 비용절감 하기</button>
	</div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

.promotion-wrapper { 
    width: 450px;
    margin: auto;
    position: relative;
    .intro {
        position: relative;
        /* height: 400px; */
        overflow: hidden;
        & > div {
            position: absolute;
            top: 60px;
            left: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .scroll {
                /* cursor: pointer; */
                width: 26px;
                height: 86px;
                margin-left: 5px;
                margin-top: 10px;
            }
            h6 {
                margin-top: 10px;
                margin-left: 5px;
                color: #d3d3d3;
                text-shadow: 2px 2px 4px rbga(0,0,0,0);
                font-weight: 700;
                font-size: 14px;
            }
            
        }
        .title {
            width: 100%;
            min-height: 387px;
        }
    }
    &>img {
		width: 100%;
	}
    button {
        z-index: 20;
        border: 1px solid #0d0d0d;
        width: 450px;
        padding: 15px 55px;
        border-radius: 10px 10px 0 0;
        position: fixed;
        bottom: 0;
		display: flex;
        word-break: keep-all;
        background: linear-gradient(180deg, #F2C438 22.03%, #F1B444 100%);
        color: #000;
        font-size: 22px;
        font-weight: 900;
    }
}
@media (max-width: 767px) {
	.promotion-wrapper {
		width: 100%;

		button {
            width: 100%;
        }
	}
}
</style>