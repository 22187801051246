<template>
    <div class="free-layout">
        <div class="free-button-layout">
            <h1>랩매니저 팀의 도움이 <br />필요하다면<br /> 아래 버튼을<br />눌러 문의를 남겨주세요!</h1>
            <button @click="goRequest">
                문의하기
            </button>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        goRequest () {
            this.$router.push({name: 'Request'})
        }
    }
}
</script>
<style lang="scss" scoped>
.free-layout {
    width: 100%;
    background-color: white;
    padding: 120px 0px;
    .free-button-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        margin: 0 auto;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
            br:nth-child(odd) {
                display: none;
            }
        }
        button {
            color:black;
            padding: 10px 80px;
            font-weight: 700;
            font-size: 25px;
            margin-top: 80px;
            background-color: #FEC741;
            border-radius: 30px;
        }
    }
}

@media (max-width: 767px) {
.free-layout {
    width: 100%;
    padding: 60px 13px;
    .free-button-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        height: 300px;
        padding: 30px;
        h1 {
            word-break: keep-all;
            text-align: center;
            font-weight: 700;
            font-size: 28px;
            line-height: 129.99%;
            br:nth-child(odd) {
                display: block;
            }
            br:nth-child(even) {
                display: none;
            }
        }
        button {
            padding: 10px 26px;
            font-weight: 700;
            font-size: 18px;
            margin-top: 40px;
            background-color: #FEC741;
            border-radius: 30px;
        }
    }
}

}
</style>