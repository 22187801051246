<template>
    <div class="price-wrapper">
        <div class="wrap">
            <h1>
                비대면 바우처에서만<br/>
                만날 수 있는 요금제입니다.
            </h1>
            <div class="options pc">
                <img src="@/assets/image/image-voucher-option-1.png" />
                <img src="@/assets/image/image-voucher-option-2.png" />
            </div>
            <div class="options mo">
                <img src="@/assets/image/image-voucher-option-1.png" />
                <img src="@/assets/image/image-voucher-option-2.png" />
            </div>
            <p>* 이용기간과 계정 수는 결제금액 내에서 자유롭게 변경 가능합니다.<br/>(단, 최소 이용기간 6개월 이상)</p>
            <div class="example-box">
                <div class="label">구매 예시</div>
                 <p>옵션1 기본 서비스 상품 결제 → <u>3계정 12개월 또는 4계정 9개월 등으로 사용 가능</u></p>
            </div>
            <p>* 부가세는 기업 부담 후 국세청을 통해 100% 환급될 예정</p>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.price-wrapper {
    background-color: #FEC741;
    .wrap{
        width: 1000px;
        margin: 0 auto;
        padding: 120px 0px 60px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            text-align: center;
            font-weight: 700;
            font-size: 40px;
            line-height: 129.99%;
        }
        .options {
            &.pc {
                margin-top: 80px;
                display: flex;
                align-items: center;
                gap: 100px;
            }
            &.mo {
                display: none;
            }
            img {
                width: 450px;
            }
        }
        & > p {
            font-size: 20px;
            font-weight: 500;
            margin-top: 60px;
            text-align: center;
        }
        .example-box {
            margin-top: 30px;
            background-color: white;
            width: 100%;
            padding: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            border-radius: 20px;
            box-shadow: 0px 2px 4px rgba(249, 178, 7, 0.5);
            .label {
                width: fit-content;
                padding: 3px 20px;
                background: linear-gradient(180deg, #FEC741 0%, #FFA90B 100%);
                border-radius: 5px;
                font-size: 20px;
                font-weight: 800;
                color:white;   
            }
            p {
                text-align: center;
                u {
                    font-weight: 600;
                }
            }
        }
    }
}
@media (max-width: 1024px) {
.price-wrapper {
    .wrap{
        width: 100%;
        padding: 60px 0px 30px 0px;
        h1 {
            font-size: 28px;
        }
        .options {
            &.pc {
                display: none;
            }
            &.mo {
                width :100%;
                margin: 0 auto;
                margin-top: 30px;
                display: grid;
                grid-auto-flow: column;
                gap: 20px;
                padding: 0px 58px;
                overflow-y: auto;
                overscroll-behavior-x: contain;
                scroll-snap-type: x mandatory;

                & > * {
                    scroll-snap-align: center;
                }
                img {
                    width: calc(100vw - 120px);
                }

                &::-webkit-scrollbar {
                    position: absolute;
                    display: none;
                }
            }
            img {
                width: 450px;
            }
        }
        & > p {
            font-size: 14px;
            margin-top: 30px;
            width: calc(100% - 60px);
            word-break: keep-all;
        }
        .example-box {
            margin-top: 20px;
            background-color: white;
            width: calc(100% - 60px);
            padding: 15px;
            gap: 10px;
            .label {
                padding: 1px 15px;
                font-size: 14px;
            }
            p {
                text-align: center;
                font-size: 14px; 
                u {
                    display: block;
                    font-weight: 600;
                }
            }
        }
    }
}
}
</style>