<template>
	<div id="Event">
		<div class="intro">
			<img src="@/assets/svg/event/event-intro.svg" />
			<button @click="goMain">
				<img src="@/assets/svg/event/event-intro-btn.svg" />
			</button>
		</div>
		<div class="content">
			<img src="@/assets/svg/event/event-content.svg" />
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		goMain() {
			window.open('https://www.lab-manager.com/?utm_source=bix&utm_medium=event_page&utm_campaign=30days_free')
		},
	},
}
</script>

<style lang="scss" scoped>
#Event {
	max-width: 640px;
	width: 100%;
	margin: 0 auto;
	.intro {
		position: relative;
		> img {
			width: 100%;
		}
		button {
			max-width: 90%;
			width: 300px;
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.content {
		img {
			width: 100%;
		}
	}
}
</style>
