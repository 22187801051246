<template>
	<b-modal id="gathering-form" ref="modal" size="lg" centered hide-footer>
		<div class="header-box">
			<b v-text="`연구에 필요한 모든 관리, 랩매니저 PRO`"></b>
			<p v-html="`이메일을 남겨주시면, <br>확인 후 빠른 시일내에 연락 드리겠습니다.`"></p>
		</div>
		<div class="modal-body">
			<h6>무료 상담하기</h6>
			<div class="form-box">
				<form-input title="이메일" :required="true" content="기업용 이메일 주소를 입력하세요." v-model="email" />
			</div>
			<button class="submit" @click="submit">신청하기</button>
		</div>
	</b-modal>
</template>

<script>
import FormInput from '@/ui/form/FormInput'

export default {
	name: 'ModalGatheringForm',
	components: {
		FormInput,
	},
	data() {
		return {
			email: '',
			manager: '',
			phone: '',
			agree: false,
		}
	},
	methods: {
		validation() {
			let isValid = true
			let emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
			if (this.email === '') {
				isValid = false
				return alert('이메일 주소를 입력해주세요.')
			} else if (!emailRule.test(this.email)) {
				isValid = false
				return alert('이메일이 형식에 맞지 않습니다. 다시 한 번 확인해주세요.')
			}

			return isValid
		},
		async submit() {
			if (!this.validation()) {
				return false
			}

			try {
				await this.$service.client.submitRequest(this.form)
				window.marketingEvent('conversion')
				alert('문의가 접수되었습니다.\n빠른 시일 내에 답변 드리도록하겠습니다.')
				this.form = {
					email: '',
					phone: '',
					manager: '',
					content: '',
				}
				this.$refs['modal'].hide()
			} catch (e) {
				alert('오류로 인해 문의가 접수되지 않았습니다.\n다시 한 번 시도해주세요')
			}
		},
	},
}
</script>

<style lang="scss">
#gathering-form {
	.modal-header {
		border-bottom: 0;
		z-index: 10;
		width: 100%;
	}

	.header-box {
		width: 100%;
		background: linear-gradient(315deg, #fec741 0%, #fed671 100%);
		margin-top: -56px;
		padding-top: 50px;
		padding-bottom: 22px;
		border-radius: 0.3rem 0.3rem 0 0;
		text-align: center;
		b {
			font-size: 1rem;
			font-weight: 600;
			margin-bottom: 8px;
			display: block;
		}
		p {
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.6;
		}
	}

	.modal-body {
		padding: 0 0;
		h6 {
			width: 100%;
			height: 55px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1rem;
			font-weight: 600;
			border-bottom: 1px solid #eee;
			margin-bottom: 26px;
		}
	}

	.flex {
		padding-left: 15px;
		margin: 1rem 0 20px;
	}
	input[type='checkbox'] {
		width: 18px;
		height: 18px;
		position: relative;
		margin-right: 10px;
		cursor: pointer;
		&:before {
			width: 20px;
			height: 20px;
			position: absolute;
			top: -1px;
			left: -1px;
			content: '';
			background-color: white;
		}
		&:after {
			width: 18px;
			height: 18px;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			background-image: url(~@/assets/svg/gathering/check-off-y.svg);
			background-size: 100%;
			background-position: center;
		}
		&:checked {
			&:after {
				background-image: url(~@/assets/svg/gathering/check-on-y.svg);
			}
		}
	}
	.submit {
		width: 100%;
		height: 56px;
		border-top: 1px solid #eee;
		border-radius: 0 0 0.3rem 0.3rem;
		font-size: 1rem;
		font-weight: 600;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
