<template>
	<div id="Service">
		<div class="intro">
			<div class="wrap">
				<h1>{{ tt('랩매니저 PRO로') }}<br>{{tt('연구실 환경이 확 바뀝니다!')}}</h1>
				<h6>{{ tt('물품관리부터 구매까지 한 번에 가능한') }}<br>{{ tt('랩매니저 PRO의 주요 기능을 살펴보세요.') }}</h6>
			</div>
		</div>
		<div class="management">
			<div class="wrap">
				<h1>{{ tt('물품관리') }}</h1>
				<h6>{{ tt('AI 인식 기술과 상품 정보 연동을 통해') }}<br>{{ tt(' 보다 편리하고 정확한 관리가 가능합니다.') }}</h6>
				<img :src="require('@/assets/svg/arrow-down.svg')" alt="down"/>
				<Swiper v-if="isMobile" :list="inventoryList" :options="listOptions">
				</Swiper>
				<div class="image_wrap" v-else>
					<img v-for="(img, index) in inventoryList" :key="`inventory_${index}`" :src="img"/>
				</div>
				
			</div>
		</div>
		<div class="management">
			<div class="wrap">
				<h1>{{ tt('안전관리') }}</h1>
				<h6>{{ tt('AI 인식 기술과 상품 정보 연동을 통해') }}<br>{{ tt(' 보다 편리하고 정확한 관리가 가능합니다.') }}</h6>
				<img :src="require('@/assets/svg/arrow-down.svg')" alt="down"/>
				<Swiper v-if="isMobile" :list="saftyList" :options="listOptions">
				</Swiper>
				<div class="image_wrap" v-else>
					<img v-for="(img, index) in saftyList" :key="`inventory_${index}`" :src="img"/>
				</div>
			</div>
		</div>
		<div class="management">
			<div class="wrap">
				<h1>{{ tt('구매관리') }}</h1>
				<h6>{{ tt('AI 인식 기술과 상품 정보 연동을 통해') }}<br>{{ tt(' 보다 편리하고 정확한 관리가 가능합니다.') }}</h6>
				<img :src="require('@/assets/svg/arrow-down.svg')" alt="down"/>
				<Swiper v-if="isMobile" :list="purchaseList" :options="listOptions">
				</Swiper>
				<div class="image_wrap" v-else>
					<img v-for="(img, index) in purchaseList" :key="`inventory_${index}`" :src="img"/>
				</div>
			</div>
		</div>
		<div class="management">
			<div class="wrap">
				<h1>{{ tt('예산관리') }}</h1>
				<h6>{{ tt('AI 인식 기술과 상품 정보 연동을 통해') }}<br>{{ tt(' 보다 편리하고 정확한 관리가 가능합니다.') }}</h6>
				<img :src="require('@/assets/svg/arrow-down.svg')" alt="down"/>
				<Swiper v-if="isMobile" :list="budgetList" :options="budgetOptions">
				</Swiper>
				<div class="image_wrap" v-else>
					<img v-for="(img, index) in budgetList" :key="`inventory_${index}`" :src="img"/>
				</div>
			</div>
		</div>
		<div class="management">
			<div class="wrap">
				<h1>{{ tt('연구생활이 더 편리해지는') }}<br>{{ tt(' 다양한 편의 기능') }}</h1>
				<h6>{{ tt('AI 인식 기술과 상품 정보 연동을 통해') }}<br>{{ tt(' 보다 편리하고 정확한 관리가 가능합니다.') }}</h6>
				<img :src="require('@/assets/svg/arrow-down.svg')" alt="down"/>
				<div class="image_wrap">
					<img v-for="(img, index) in convenienceList" :key="`inventory_${index}`" :src="img"/>
				</div>
			</div>
		</div>
		<div class="more">
			<div class="wrap">
				<div>
					<h1 v-if="isMobile" v-html="tt('이 모든 기능을 사용해<br>보고 싶다면 무료사용으로<br> 경험해 보세요.')"></h1>
					<h1 v-else v-html="tt('이 모든 기능을 사용해 보고 싶다면<br> 무료사용으로 경험해 보세요.')"></h1>
					<h6>{{ tt('지금, 바로 랩매니저 PRO를 시작해 보세요!') }}</h6>
					<div class="button-wrap">
						<button @click="$root.openApp" class="btn-black">{{ tt('무료사용') }}</button>
						<button @click="goRequest" class="btn-down">{{ tt('문의하기') }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import Carousel from '@/components/Carousel2.vue';
import Swiper from '@/components/Swiper.vue';
export default {
	name: 'Service',
	components: {
		// Carousel,
		Swiper,
	},
	metaInfo: {
		title: '주요기능_랩매니저 PRO',
		meta: [
			{vmid:'keywords', name:'keywords', content: 'labmanager, lab manager, 랩매니저, 랩 매니저, 랩매니저 프로, 랩매니저 PRO, smartjack, 스마트잭, 연구실 관리, 시약 관리, 연구실 시약관리, MSDS, 주요기능, 물품관리, 연구실 재고관리, 재고관리, 안전관리' },
			{vmid:'description', name:'description', content: '랩매니저 PRO 주요기능, 물품(재고)관리-안전관리-구매관리-예산관리 모두 가능한 올인원 시약 및 연구물품 관리 솔루션. 실시간 시약 위치 확인, 사용량 및 잔량 확인, MSDS 70만종 자동연동, 안전법령별 물품 관리대장 자동입력 및 다운로드' },
			{vmid:'og:description', property:'description', content: '랩매니저 PRO 주요기능, 물품(재고)관리-안전관리-구매관리-예산관리 모두 가능한 올인원 시약 및 연구물품 관리 솔루션. 실시간 시약 위치 확인, 사용량 및 잔량 확인, MSDS 70만종 자동연동, 안전법령별 물품 관리대장 자동입력 및 다운로드' },
		]
	},
	computed: {
		isMobile() {
			return window.matchMedia("(max-width: 767px)").matches ?? false
		},
		showTooltip() {
			return !this.isMobile ?? false
		},
		inventoryList() {
			return this.isMobile ? [
				{
					images: [
						require('@/assets/image/service/m_물품관리_01.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_물품관리_02.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_물품관리_03.png'),
					],
				},
			] : [
				require('@/assets/image/service/물품관리_01.png'),
				require('@/assets/image/service/물품관리_02.png'),
				require('@/assets/image/service/물품관리_03.png')
			]
		},
		saftyList() {
			return this.isMobile ? [
				{
					images: [
						require('@/assets/image/service/m_안전관리_01.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_안전관리_02.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_안전관리_03.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_안전관리_04.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_안전관리_05.png'),
					],
				},
			] : [
				require('@/assets/image/service/안전관리_01.png'),
				require('@/assets/image/service/안전관리_02.png'),
				require('@/assets/image/service/안전관리_03.png'),
				require('@/assets/image/service/안전관리_04.png'),
				require('@/assets/image/service/안전관리_05.png'),
			]
		},
		purchaseList() {
			return this.isMobile ? [
				{
					images: [
						require('@/assets/image/service/m_구매관리_01.png'),
					],
				},
				{
					images: [
						require('@/assets/image/service/m_구매관리_02.png'),
					],
				},
			] : [
				require('@/assets/image/service/구매관리_01.png'),
				require('@/assets/image/service/구매관리_02.png'),
			]
		},
		budgetList() {
			return this.isMobile ? [
				{
					images: [
						require('@/assets/image/service/m_예산관리.png'),
					],
				},
			] : [
				require('@/assets/image/service/예산관리_01.png'),
			]
		},
		convenienceList() {
			return this.isMobile ? [
				require('@/assets/image/service/m_편의기능_01.png'),
				require('@/assets/image/service/m_편의기능_02.png'),
				require('@/assets/image/service/m_편의기능_03.png'),
				require('@/assets/image/service/m_편의기능_04.png'),
				require('@/assets/image/service/m_편의기능_05.png'),
				require('@/assets/image/service/m_편의기능_06.png'),
			] : [
				require('@/assets/image/service/편의기능_01.png'),
				require('@/assets/image/service/편의기능_02.png'),
				require('@/assets/image/service/편의기능_03.png'),
				require('@/assets/image/service/편의기능_04.png'),
				require('@/assets/image/service/편의기능_05.png'),
				require('@/assets/image/service/편의기능_06.png'),
			]
		},
	},
	data() {
		return {
			listOptions: {
				loop: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
			},
			budgetOptions: {
				pagination: {
					el: ".swiper-pagination",
					clickable: true
				},
			},
		}
	},
	methods: {
		goRequest() {
			this.$router.push({
				name: 'Request',
			})
		},
	},
}
</script>

<style lang="scss">
@import '@/assets/css/_service.scss';
</style>
