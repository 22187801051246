import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Services from '@/services'
import localization from '@/localization'
import { store } from './store'
import { BootstrapVue } from 'bootstrap-vue'
import VueCarousel from 'vue-carousel'
import VueGlide from 'vue-glide-js'
import VueCookies from 'vue-cookies'
import 'vue-glide-js/dist/vue-glide.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Meta from 'vue-meta';


import 'swiper/dist/css/swiper.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Meta, {
	keyName: 'metaInfo',
	attribute: 'data-vue-meta',
	ssrAttribute: 'data-vue-meta-server-rendered',
	tagIDKeyName: 'vmid',  
	refreshOnceOnNavigation: true
  })
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(VueCarousel)
Vue.use(localization)
Vue.use(VueGlide)

Vue.config.productionTip = false
Vue.prototype.$cookies = VueCookies
Vue.prototype.$service = Services
Vue.$cookies.config('30d')

import '@/assets/css/common.scss'

new Vue({
	store: store,
	router,
	localization,
	render: h => h(App),
	data() {
		return {
			language: 'ko',
		}
	},
	created() {
		AOS.init({
			duration: 1200,
		})
		this.language =
			(!this.$route.query.language && navigator.language.includes('en')) || (this.$route.query.language && this.$route.query.language == 'en')
				? 'en'
				: 'ko'
	},
	mounted() {
		this.setUtmCookies()
	},
	computed: {
		isPc() {
			var a = ''
			if (/iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase())) {
				var i = navigator.userAgent.toLowerCase()
				a = i.search('android') > -1 ? 'android' : i.search('iphone') > -1 || i.search('ipod') > -1 || i.search('ipad') > -1 ? 'ios' : 'else'
			} else a = 'pc'

			return a == 'pc' ? true : false
		},
	},
	methods: {
		setUtmCookies() {
			Object.keys(this.$route.query).forEach(key => {
				if (key.includes('utm')) {
					this.$cookies.set(key, this.$route.query[key], null, null, '.lab-manager.com')
				}
			})
		},
		openApp (){
			this.openLoginApp()
            // if(this.$root.isPc) {
			// 	const token = this.$cookies.get('app.token')
			// 	if(token) {
			// 		window.open('https://pro.lab-manager.com')
			// 	}
            //     window.open('https://accounts.lab-manager.com/pro/Terms?redirect=https%3A%2F%2Fpro.lab-manager.com&pro=true')
            // } else {
            //     window.open('https://lmpro.page.link/app')
            // }
        },
		openLoginApp (){
            if(this.$root.isPc) {
				const token = this.$cookies.get('app.token')
				if(token) {
					window.open('https://pro.lab-manager.com')
				}
                window.open('https://accounts.lab-manager.com/pro/signin?redirect=https%3A%2F%2Fpro.lab-manager.com&pro=true')
            } else {
                window.open('https://lmpro.page.link/app')
            }
        }
	},
}).$mount('#app')
